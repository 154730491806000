import classNames from 'classnames';
import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { SupplyRunLeaderboardData, SupplyRunLeaderboardUser } from '@/models/misc';
import { useTheme } from '@/theme';
import {
    openProfile,
    setIsExitLocationModalOpen,
    setIsInventoryOpen,
    setIsSupplyRunLeaderboardOpen,
} from '@/state/features/app';
import { getSupplyRunLeaderboardData } from '@/arise/api';
import { processProfileData } from '@/utils/profile';
import { sendEvent } from '@/utils/analytics';
import { convertNumberToOrdinal, localiseNumber } from '@/utils/string';
import Modal from '@/components/Modal';
import DisplayName from '@/components/DisplayName';
import AvatarLayered from '@/components/AvatarLayered';
import { ProfileStatSummaryItem } from '@/components/ProfileDrawer/ProfileStatsSummary';
import Icon from '@/components/Icon';

import * as styles from './styles.module.scss';

export default function SupplyRunLeaderboard() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isSupplyRunLeaderboardOpen = useAppSelector((state) => state.app.isSupplyRunLeaderboardOpen);
    const [leaderboardData, setLeaderboardData] = useState<SupplyRunLeaderboardData>(null);
    const [isError, setIsError] = useState(false);

    async function getLeaderboardData() {
        try {
            const response = await getSupplyRunLeaderboardData();
            setLeaderboardData(response);
        } catch (error) {
            console.warn('Could not get supply run data:', error);
            setIsError(true);
        }
    }

    useEffect(() => {
        if (isSupplyRunLeaderboardOpen) {
            getLeaderboardData();
            setIsExitLocationModalOpen(false);
            setIsInventoryOpen(false);
            sendEvent('open_leaderboard');
        } else {
            setTimeout(() => {
                // Timeout to allow modal to move off screen
                setLeaderboardData(null);
                setIsError(false);
            }, 500);
        }
    }, [isSupplyRunLeaderboardOpen]);

    return (
        <Modal
            modalClassname={styles.Modal}
            title="Supply Run Leaderboard"
            isVisible={isSupplyRunLeaderboardOpen}
            onClose={() => {
                dispatch(setIsSupplyRunLeaderboardOpen(false));
            }}
            canScroll
            isLoading={!leaderboardData && !isError}
            backgroundImageOverride={theme.customJSON.leaderboardBackgroundImageUrl}
        >
            {isError && <h3>Something went wrong, please try again later.</h3>}
            <Leaderboard data={leaderboardData} />
        </Modal>
    );
}

const processUsers = (users: SupplyRunLeaderboardUser[]) => {
    const MAX_ENTRIES_PER_TABLE = 20;
    const sliced = users.slice(0, MAX_ENTRIES_PER_TABLE);
    const processed = sliced.map((user) => {
        return {
            ...user,
            profile: processProfileData(user.profile),
        };
    });
    return processed;
};

const ResultsTable = ({ title, users }: { title: string; users: SupplyRunLeaderboardUser[] }) => {
    const dispatch = useAppDispatch();
    if (!users?.length) {
        return (
            <>
                <h3>{title}</h3>
                <div className={styles.empty}>
                    <Icon type="supplies" />
                    <p>No supplies yet!</p>
                </div>
                <br />
            </>
        );
    }

    return (
        <>
            <h3>{title}</h3>

            <table>
                <thead>
                    <tr>
                        <th className={styles.positionTitle}>#</th>
                        <th className={styles.userTitle}>User</th>
                        <th>Supplies</th>
                    </tr>
                </thead>
                <tbody>
                    {processUsers(users).map((user, index) => (
                        <tr key={index} className={classNames({ [styles.first]: index === 0 })}>
                            <td className={styles.position}>{user.position}</td>
                            <td>
                                <div className={styles.user}>
                                    <AvatarLayered
                                        classname={styles.avatar}
                                        backgroundImagePath={user.profile.background?.thumbnailPath}
                                        characterImagePath={user.profile.character?.thumbnailPath}
                                        frameImagePath={user.profile.avatarFrame?.imagePath}
                                        onClick={() => dispatch(openProfile(user.profile))}
                                        title={`View ${user.profile.displayName}'s Profile`}
                                    />
                                    <DisplayName
                                        profile={user.profile}
                                        shouldLinkToProfile
                                        classname={classNames({
                                            [styles.isLong]: user.profile.displayName.length > 12,
                                        })}
                                    />
                                </div>
                            </td>
                            <td>{localiseNumber(user.score, 'N/A')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const Leaderboard = ({ data }: { data: SupplyRunLeaderboardData }) => {
    enum TabType {
        Today = 'Today',
        AllTime = 'AllTime',
    }

    const [currentTab, setCurrentTab] = useState<TabType>(TabType.Today);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const dispatch = useAppDispatch();

    if (!data) return null;
    const { globalLeaderboard, userStats } = data;
    return (
        <div className={classNames(styles.SupplyRunLeaderboardTable, styles[currentTab])}>
            <div className={styles.tabButtons}>
                <button
                    onClick={() => {
                        setCurrentTab(TabType.Today);
                        sendEvent('leaderboard_tab_click', { tab: TabType.Today });
                    }}
                    className={classNames({ [styles.isCurrent]: currentTab === TabType.Today })}
                    disabled={currentTab === TabType.Today}
                >
                    Today
                </button>
                <button
                    onClick={() => {
                        setCurrentTab(TabType.AllTime);
                        sendEvent('leaderboard_tab_click', { tab: TabType.AllTime });
                    }}
                    className={classNames({ [styles.isCurrent]: currentTab === TabType.AllTime })}
                    disabled={currentTab === TabType.AllTime}
                >
                    All Time
                </button>
            </div>
            {userStats && (
                <div className={styles.userStats}>
                    <div className={styles.profile}>
                        <AvatarLayered
                            classname={styles.avatar}
                            backgroundImagePath={currentUserProfile.background?.thumbnailPath}
                            characterImagePath={currentUserProfile.character?.thumbnailPath}
                            frameImagePath={currentUserProfile.avatarFrame?.imagePath}
                            onClick={() => dispatch(openProfile(currentUserProfile))}
                        />
                        <DisplayName profile={currentUserProfile} shouldLinkToProfile={true} />
                    </div>
                    <ul className={styles.stats}>
                        <li>
                            <ProfileStatSummaryItem
                                iconType="supplies"
                                title={currentTab === TabType.Today ? 'Supplies Today:' : 'All Time Supplies:'}
                                value={
                                    currentTab === TabType.Today
                                        ? localiseNumber(userStats.totalAccumulatedThisPeriod?.score, 0)
                                        : localiseNumber(userStats.totalAccumulated?.score, 0)
                                }
                            />
                        </li>
                        <li>
                            <ProfileStatSummaryItem
                                iconType="bolt"
                                title={currentTab === TabType.Today ? 'Leaderboard Today:' : 'All Time Leaderboard:'}
                                value={
                                    currentTab === TabType.Today
                                        ? convertNumberToOrdinal(userStats.totalAccumulatedThisPeriod?.position)
                                        : convertNumberToOrdinal(userStats.totalAccumulated?.position)
                                }
                            />
                        </li>
                    </ul>
                </div>
            )}
            {currentTab === TabType.Today && (
                <>
                    <ResultsTable title="Today's Top Suppliers" users={globalLeaderboard.totalAccumulatedThisPeriod} />
                    <ResultsTable
                        title="Today's Best Single Run"
                        users={globalLeaderboard.highestSingleRunThisPeriod}
                    />
                </>
            )}
            {currentTab === TabType.AllTime && (
                <>
                    <ResultsTable title="All Time Top Suppliers" users={globalLeaderboard.totalAccumulated} />
                    <ResultsTable title="All Time Best Single Run" users={globalLeaderboard.highestSingleRun} />
                </>
            )}
        </div>
    );
};
