import { useTheme } from '@/theme';

import * as styles from './styles.module.scss';
import classNames from 'classnames';

interface LoadingScreenProps {
    show: boolean;
    classname?: string;
    hasBackground?: boolean;
}

export default function LoadingScreen({ show, classname = '', hasBackground = true }: LoadingScreenProps) {
    const theme = useTheme();

    return (
        <div
            aria-label="Loading"
            className={classNames('absolute left-0 w-full h-full', styles.loadingScreen, classname, {
                [styles.hidden]: !show,
            })}
        >
            <div
                className={classNames('absolute top-0 left-0 w-full h-full', {
                    'bg-black bg-opacity-50 backdrop-blur-md': hasBackground,
                })}
            ></div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <div className="relative w-[150px] h-[150px]">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUyIiBoZWlnaHQ9IjE1MiIgdmlld0JveD0iMCAwIDE1MiAxNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8ZyBpZD0iTG9hZGVyQW5pbWF0aW9uIj4KICAgICAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlIDMiIGN4PSI3NiIgY3k9Ijc2IiByPSI3NSIgc3Ryb2tlPSJ1cmwoI3BhaW50MF9saW5lYXJfNDE5XzEzNTIpIiAvPgogICAgPC9nPgogICAgPGRlZnM+CiAgICAgICAgPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzQxOV8xMzUyIiB4MT0iNzYiIHkxPSIxIiB4Mj0iNzYiIHkyPSI3NiIKICAgICAgICAgICAgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIgLz4KICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIiAvPgogICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICA8L2RlZnM+Cjwvc3ZnPg=="
                        alt=" "
                        className={'absolute top-0 left-0 w-full h-full ' + styles.loadingSpinner}
                    />
                    {theme.files.logo && (
                        <img
                            src={theme.files.logo}
                            alt=" "
                            className="absolute top-0 left-0 w-full h-full scale-[.6]"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
