@import '../../style/globals.scss';

.MarkdownRenderer {
    &.hasDefaultStyling {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding-bottom: 5px;
            font-size: $font-size-h6;
        }

        p {
            padding-bottom: 15px;
            font-size: $font-size-p;
        }

        li {
            list-style-type: disc; /* Ensures bullets are shown */
        }

        ul {
            display: inline-block;
            margin-bottom: 10px;
            margin-left: 20px;
            font-size: $font-size-p;
            text-align: left;
        }

        a {
            color: var(--token-accent-theme-1-text-text-link);

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &.centerAlign {
        text-align: center;
    }

    &.leftAlign {
        text-align: left;
    }
}
