@import '../../style/globals.scss';

.GuestSignUpModal {
    height: auto;
    font-weight: 300;

    .joinButton {
        display: inline-block;
        width: fit-content;
        min-width: 200px;
        margin-top: 20px;
    }

    img {
        max-width: 100%;
        padding: 10px 0px;
        display: inline-block;
        min-width: 80px;
    }
}
