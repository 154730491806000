@import '../../../style/globals.scss';

.SuggestionsPicker {
    @include scrollbar-styles;
    position: absolute;
    bottom: 100%;
    overflow-y: auto;
    width: 100%;
    max-height: 105px;
    border: 1px solid var(--token-accent-theme-1-border-border-default);
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
    color: var(--token-accent-theme-1-text-text-default);
    border-radius: 10px;

    &.hidden {
        display: none;
    }

    &__closeButton {
        position: sticky;
        z-index: 10;
        top: 4px;
        right: 0;
        width: 35px;
        height: 0px;
        float: right;
    }

    &__user {
        position: relative;
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
        text-align: left;

        .avatar {
            height: 25px;
            width: 25px;
            display: inline-block;
            margin-right: 10px;
            vertical-align: bottom;
        }

        &.active {
            background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
        }
    }

    &__helpText {
        display: inline-block;
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
    }
}
