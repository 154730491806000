@import '../../../style/globals.scss';

$background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
$active-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);

.showButton,
.exisitingReaction {
    display: inline-block;
    height: 21px;
    margin-right: 5px;
    background-color: $background-color;
    user-select: none;
    transition: background-color 0.2s ease-in-out;
    border-radius: 50px;
}

.showButton {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 44px;
    padding-left: 8px;
    font-size: $font-size-p;
    font-weight: bold;
    text-align: left;
    background-color: $active-color;

    span {
        top: -1px;
        position: relative;
    }

    &.isOpen {
        background-color: $active-color;
    }

    color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
    svg {
        margin-top: -2px;
        path {
            fill: var(--token-LEGACY-accent-theme-1-text-text-secondary);
        }
    }

    &:hover,
    &:focus {
        color: var(--token-LEGACY-accent-theme-1-text-text-active);
        svg {
            path {
                fill: var(--token-LEGACY-accent-theme-1-text-text-active);
            }
        }
    }
}

.reactionMenuContainer {
    position: absolute;
    top: -47px;
    right: 10px;
    display: block;
    height: 47px;
    padding-top: 5px;
    padding-bottom: 15px;

    .reactionMenu {
        overflow: hidden;
        height: 27px;
        padding: 0px 6px; // Also in ts file
        background-color: $background-color;
        white-space: nowrap;
        border-radius: 50px;
        transition: max-width 300ms $animation-bounce;
        opacity: 0;

        img {
            display: inline;
            max-width: 20px;
            max-height: 20px;
            margin-top: -2px;
        }

        &.show {
            overflow: visible;
            opacity: 1;

            .reactionMenuButton {
                opacity: 1;
            }
        }

        &Button {
            position: relative;
            top: 1.5px;
            width: 24px; // Also in ts file
            height: 24px;
            margin-left: 1.5px;
            opacity: 0;
            border-radius: 50px;
            transform: scale(1);
            user-select: none;
            -webkit-user-drag: none;
            transition: transform 150ms $animation-bounce, background-color 150ms $animation-bounce,
                opacity 500ms $animation-bounce;

            &.active {
                background-color: $active-color;
            }

            &:hover,
            &:focus {
                transform: scale(1.25);
                background-color: transparent;

                span {
                    bottom: 10px;
                }
            }

            span {
                position: relative;
                bottom: 0px;
                transition-duration: 150ms;
                transition-timing-function: $animation-bounce;
                transition-property: bottom;
            }
        }
    }
}

@media (max-width: $bp-mobile-chat) {
    .reactionMenu {
        transform-origin: bottom right;
        transform: scale(1.5);
    }
}

.existingReactions {
    display: inline-block;
    height: 21px;
    margin-top: 6px;
}

.exisitingReaction {
    position: relative;
    display: inline-block;
    padding: 0px 5px;
    margin-bottom: 2px;
    font-size: $font-size-p;
    font-weight: bold;
    user-select: none;
    -webkit-user-drag: none;

    img {
        display: inline;
        max-width: 16px;
        max-height: 16px;
        vertical-align: text-top;
    }

    &:hover {
        transform: scale(1.1);
    }

    &.active {
        background-color: $active-color;
    }
}
