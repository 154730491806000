import { useAppDispatch, useAppSelector } from '@/state/store';
import { setShouldShowGuestSignUpModal } from '@/state/features/app';
import { useTheme } from '@/theme';
import { sendEvent } from '@/utils/analytics';
import Modal from '@/components/Modal';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import { ThemedLinkButton } from '@/components/ThemedButton';
import { guestRegistrationPath, isMapPath } from '@/theme/vars';
import { useCurrentUserIsGuest } from '@/hooks/users';

import * as styles from './styles.module.scss';

export default function GuestSignUpModal() {
    const isGuest = useCurrentUserIsGuest();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const hasData = theme.customJSON?.guestSignUpModal?.markdown && theme.customJSON?.guestSignUpModal?.title;

    const guestModalCount = useAppSelector((state) => state.app.guestModalCount);
    const shouldShowGuestSignUpModal = useAppSelector((state) => state.app.shouldShowGuestSignUpModal);

    if (!isGuest || !hasData || !isMapPath()) return;

    // Only show modal every other time it is requested by app
    const shouldShow = shouldShowGuestSignUpModal && guestModalCount % 2 !== 0;
    if (shouldShow) {
        sendEvent('view_guest_signup_modal');
    }

    return (
        <Modal
            modalClassname={styles.GuestSignUpModal}
            zIndex={1000}
            title={theme.customJSON?.guestSignUpModal?.title}
            onClose={() => {
                dispatch(setShouldShowGuestSignUpModal(false));
                sendEvent('close_guest_signup_modal');
            }}
            isVisible={shouldShow}
            canScroll={true}
            leaveSpaceAboveAndBelow={false}
        >
            {theme.customJSON?.guestSignUpModal?.imageUrl && (
                <img src={theme.customJSON?.guestSignUpModal?.imageUrl} alt=" " />
            )}
            <MarkdownRenderer markdownContent={theme.customJSON?.guestSignUpModal?.markdown} />
            <ThemedLinkButton
                className={styles.joinButton}
                href={guestRegistrationPath}
                onClick={() => sendEvent('signup_guest_signup_modal')}
                text="Sign Up"
            />
        </Modal>
    );
}
