export const mapPath =
    process.env.PUBLIC_INSTANCE === 'titan' && process.env.PUBLIC_TITAN_CAMP_ID
        ? `/location/${process.env.PUBLIC_TITAN_CAMP_ID}`
        : '/selector';

export const isMapPath = (): boolean => {
    const url = new URL(window.location.href);
    return url?.pathname === mapPath;
};

export const exitModalText = process.env.PUBLIC_INSTANCE === 'titan' ? 'Back to Camp' : 'Back to World';

export const getInsidersLoginUrl = () => {
    const INSIDERS_DOMAIN = process.env.PUBLIC_INSIDERS_DOMAIN;
    const returnUrl = window.location.origin + '/auth/insiders';
    const base64Url = btoa(returnUrl);
    return `${INSIDERS_DOMAIN}/sts-login?return_to=${base64Url}`;
};

export const getInsidersRegisterUrl = () => {
    const INSIDERS_DOMAIN = process.env.PUBLIC_INSIDERS_DOMAIN;
    const returnUrl = window.location.origin + '/auth/insiders';
    const base64Url = btoa(returnUrl);
    return `${INSIDERS_DOMAIN}/sts-signup?return_to=${base64Url}`;
};

export const guestRegistrationPath = process.env.PUBLIC_INSTANCE === 'titan' ? getInsidersRegisterUrl() : '/auth/register';
