@import '../../style/globals.scss';

.Sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    // Needed for safari
    -webkit-backdrop-filter: blur(10px);
}

.top {
    height: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 50;
    background-color: rgba(0, 0, 0, 1);
    border-bottom: $line-style;

    .title {
        text-align: center;
        text-transform: uppercase;
        font-family: var(--font-secondary);
        font-size: $font-size-h6;
        line-height: normal;
    }

    h1.title {
        color: var(--token-LEGACY-accent-theme-1-text-text-active);
    }
    h2.title {
        color: var(--token-LEGACY-accent-theme-1-brand-colour);
    }
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.shopButton {
    position: absolute;
    top: 10px;
    left: 10px;
}

.bottom {
    height: calc(100% - $header-height);
    position: relative;
}

.infobar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    padding: 20px;
    // Top to bottom fade out gradient
    background: linear-gradient(
        0deg,
        transparent 0%,
        var(--token-LEGACY-accent-theme-1-interface-interface-black) 100%
    );
    pointer-events: none;

    @media (max-height: 900px) {
        height: 200px;
    }

    @media (max-height: 700px) {
        height: 70px;
    }
}

.guestChatCover {
    // Covers bottom of chat for guests
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 400px;
    padding: 20px;
    // bottom to top fade out gradient
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 50%, transparent 100%);
    pointer-events: none;

    @media (max-height: 900px) {
        height: 300px;
    }
}

.feedContainer {
    width: 100%;
    height: 100%;
}
