import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { LS_NEWSMODAL_LAST_VERSION_SEEN_KEY } from '@/globals';
import { setNewsModalLastVersionSeen } from '@/state/features/app';
import { useTheme } from '@/theme';
import Modal from '@/components/Modal';
import MarkdownRenderer from '@/components/MarkdownRenderer';

import * as styles from './styles.module.scss';
import { sendEvent } from '@/utils/analytics';

export default function NewsModal() {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const newsModalLastVersionSeen = useAppSelector((state) => state.app.newsModalLastVersionSeen);
    const shouldRender = theme.customJSON?.newsModal?.markdown && theme.customJSON?.newsModal?.title;
    const currentVersion = theme.customJSON?.newsModal?.version;

    useEffect(() => {
        if (!shouldRender) return;
        // Only show user modal once, until version changes
        const lastVersionSeen =
            newsModalLastVersionSeen || window.localStorage.getItem(LS_NEWSMODAL_LAST_VERSION_SEEN_KEY);
        if (currentVersion && currentVersion !== lastVersionSeen) {
            setIsOpen(true);
            window.localStorage.setItem(LS_NEWSMODAL_LAST_VERSION_SEEN_KEY, currentVersion);
            dispatch(setNewsModalLastVersionSeen(currentVersion));
            sendEvent('newsmodal_shown', { version: currentVersion });
        }
    }, [theme]);

    if (!shouldRender) return;

    return (
        <Modal
            zIndex={1000}
            modalClassname={styles.NewsModal}
            title={theme.customJSON?.newsModal?.title}
            onClose={() => {
                setIsOpen(false);
                sendEvent('newsmodal_closed', { version: currentVersion });
            }}
            isVisible={isOpen}
            canScroll={true}
            leaveSpaceAboveAndBelow={false}
            contentClassname={styles.content}
        >
            {theme.customJSON?.newsModal?.imageUrl && <img src={theme.customJSON?.newsModal?.imageUrl} alt=" " />}
            <MarkdownRenderer markdownContent={theme.customJSON?.newsModal?.markdown} textAlign="center" />
        </Modal>
    );
}
