@import '../../../style/globals.scss';

.Button {
    display: block;
    border-radius: 10px;
    padding: 15px;
    font-size: $font-size-p;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    width: 100%;

    background-color: var(--token-accent-theme-1-button-button-default);
    color: var(--token-accent-theme-1-text-text-default);

    transition-property: all;
    transition-duration: 0.3s;

    &:focus,
    &:hover {
        background-color: var(--token-accent-theme-1-button-button-highlight);
    }

    &:disabled {
        color: var(--token-accent-theme-1-text-text-inactive);
        background-color: var(--token-accent-theme-1-button-button-inactive);
    }

    &.secondary {
        background-color: var(--token-accent-theme-1-button-button-secondary-default);
        color: var(--token-accent-theme-1-text-text-contrast-default);

        &:focus,
        &:hover {
            background-color: var(--token-accent-theme-1-button-button-secondary-highlight);
        }
    }
}
