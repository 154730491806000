import classNames from 'classnames';

import { useTheme } from '@/theme';
import { pathToURL } from '@/utils/urls';

import * as styles from './styles.module.scss';

interface AvatarLayeredProps {
    backgroundImagePath: string;
    characterImagePath: string;
    frameImagePath?: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    title?: string;
    classname?: string;
    imageHeightDimension?: number;
}

export default function AvatarLayered({
    backgroundImagePath,
    characterImagePath,
    frameImagePath,
    onClick,
    onMouseEnter,
    title,
    classname,
    imageHeightDimension = 120,
}: AvatarLayeredProps) {
    const theme = useTheme();

    const characterImageUrl = characterImagePath ? pathToURL(characterImagePath) : null;
    const backgroundImageUrl = backgroundImagePath ? pathToURL(backgroundImagePath) : null;

    const characterImage = characterImageUrl ?? theme.customJSON.fallbackCharacterImageUrl;
    const backgroundImage = backgroundImageUrl ?? theme.customJSON.fallbackBackgroundImageUrl;

    const props = {
        className: classNames(styles.AvatarLayered, classname, { [styles.hasHover]: onClick }),
        title,
    };

    const size = `?h=${imageHeightDimension}`;

    const content = (
        <>
            {frameImagePath && (
                <img className={styles.frame} src={pathToURL(frameImagePath) + size} alt=" " aria-hidden={true} />
            )}
            <div className={styles.masked}>
                {backgroundImage && (
                    <img className={styles.background} src={backgroundImage + size} alt=" " aria-hidden={true} />
                )}
                {characterImage && (
                    <img className={styles.character} src={characterImage + size} alt=" " aria-hidden={true} />
                )}
            </div>
        </>
    );

    if (onClick) {
        return (
            <button {...props} onClick={onClick} onMouseEnter={onMouseEnter} aria-label={title ? title : 'Avatar'}>
                {content}
            </button>
        );
    } else {
        return (
            <div {...props} aria-label={title ? title : 'Avatar'} onMouseEnter={onMouseEnter}>
                {content}
            </div>
        );
    }
}
