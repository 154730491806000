@import '../../style/globals.scss';

.Rank {
    position: relative;
    opacity: 1;
    transition: opacity 200ms ease-in-out;

    &.isLoading {
        opacity: 0;
    }

    .label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-family: var(--font-primary);
        font-size: $font-size-h6;
        text-align: center;
        text-shadow: 2px 2px 5px black;
    }

    .fallbackImage {
        position: absolute;
        top: 30px;
        left: 30px;
        display: block;
        min-width: 100px;
        max-height: 130px;
    }

    .RankBadge {
        position: absolute;
        top: 30px;
        left: 0;
        width: 100%;
        min-width: 100px;
        aspect-ratio: 1/1;

        .image,
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .overlay {
            mix-blend-mode: multiply;
            mask-size: 100% 100%;
        }
    }
}
