@import '../../style/globals.scss';

.InventoryDrawer {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 50%;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-delay: 10ms;

    transform: translate(-50%, calc(100% + 30px));

    &__visible {
        transform: translate(-50%, 0%);
    }
}

.Inventory {
    @include border-image();
    position: relative;
    width: 500px;
    padding: 50px 50px 30px 50px;
}

.closeButton {
    position: absolute;
    top: -15px;
    right: -15px;
}

.pageControls {
    position: relative;
    bottom: 0;
    left: 50%;
    width: 150px;
    text-align: center;
    transform: translateX(-50%);
}

.previousButton {
    position: absolute;
    left: 0;
    transform: scaleX(-1);
}

.nextButton {
    position: absolute;
    right: 0;
}

.pageNumber {
    display: inline-block;
}

.items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    align-content: start;

    .box {
        margin-bottom: 5%;
        width: 48.5%;
    }
}

.info {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: $bp-s), screen and (max-height: 800px) {
    .Inventory {
        width: 350px;
        padding: 30px;
    }
}
