@import '../../style/globals.scss';

.IconButton {
    position: relative;

    &:disabled {
        pointer-events: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 15px;
        height: 15px;
        background-color: var(--token-arise-theme-1-state-state-error-light);
        border-radius: 50%;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }

    &.hasDot:after {
        opacity: 1;
    }
}

.isRoundStyle {
    width: 50px;
    height: 50px;
    background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
    border-radius: 100%;

    svg {
        path {
            fill: var(--token-accent-theme-1-text-text-default);
        }
    }

    &:focus,
    &:hover {
        filter: brightness(1.4);
    }
}

.isIconStyle {
    width: 24px;
    height: 24px;

    svg {
        vertical-align: baseline;

        path {
            fill: var(--token-LEGACY-accent-theme-1-interface-interface-grey);
        }
    }

    &:focus,
    &:hover {
        svg {
            path {
                fill: var(--token-LEGACY-accent-theme-1-interface-interface-white);
            }
        }
    }
}

.isDiamondStyle {
    width: 75px;
    height: 75px;
    mask-image: url('/assets/themes/titan/images/diamond-button-bg.svg');
    mask-size: 100% 100%;
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);

    svg {
        path {
            fill: var(--token-LEGACY-accent-theme-1-button-button-secondary);
        }
    }

    &:focus,
    &:hover {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-active);
        svg {
            path {
                fill: var(--token-accent-theme-1-button-button-highlight);
            }
        }
    }
}
