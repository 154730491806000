@import '../../../style/globals.scss';

$iconTransitionDuration: 200ms;
$heightTransitionDuration: 100ms;
// https://stackoverflow.com/questions/69223434/move-relative-object-position-when-scrollbar-appears-using-css

.ChatBarWrapper {
    border: 1px solid var(--token-accent-theme-1-border-border-default);
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);
    border-radius: 10px;

    &.hasToolbar {
        padding-bottom: 30px;

        .Chatbar {
            min-height: 40px;

            &__input {
                height: 40px;
                padding-bottom: 0px;
            }

            &__sendButton {
                top: calc(100% - 26px);
            }
        }
    }

    .helpButton {
        position: absolute;
        bottom: 10px;
        left: 18px;
        width: 16px;
        height: 16px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.Chatbar {
    @include scrollbar-styles;
    position: relative;
    overflow-y: hidden;
    width: 100%;
    min-height: 52px;
    max-height: 116px;
    transition: height $heightTransitionDuration linear, border $iconTransitionDuration linear;
    font-family: var(--font-tertiary);
    font-size: $font-size-p;
    cursor: text;

    &__input {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        width: 100%;
        height: 52px;
        padding: 15px 42px 15px 20px;
        background-color: transparent;
        color: var(--token-accent-theme-1-text-text-default);
        font-style: normal;
        font-weight: 400;
        line-height: 21.5px;
        outline: none;
        resize: none;
        transition: height $heightTransitionDuration linear;
        word-break: break-word;

        &:empty::after {
            content: attr(placeholder);
            font-family: var(--font-secondary);
            transition: color $iconTransitionDuration linear;
            font-weight: 400;
            color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
        }

        &:hover,
        &:focus {
            &:empty::after {
                color: var(--token-accent-theme-1-text-text-default);
            }
        }

        // Hide send button when no text in chatbar
        &:empty + .Chatbar__sendButton {
            opacity: 0;
            visibility: hidden;

            svg {
                transform: scaleX(0) translateX(30%);
            }
        }
    }

    &__richText {
        position: absolute;
        pointer-events: none;
        color: transparent;
        height: 100%;
    }

    &__mentionInProgress {
        display: inline-block;
        color: var(--token-LEGACY-accent-theme-1-text-text-secondary);
        font-weight: bold;
    }

    &__mention {
        display: inline-block;
        color: var(--token-arise-theme-1-state-state-success-light);
        font-weight: bold;
    }

    &__command {
        display: inline-block;
        padding-right: 2px;
        color: var(--token-accent-theme-1-button-button-highlight);
        font-weight: bold;
    }

    &__sendButton {
        float: right;
        position: sticky;
        top: calc(100% - 38px);
        margin-right: 8px;
        transition: opacity $iconTransitionDuration ease-in-out, visibility $iconTransitionDuration ease-in-out;

        svg {
            transition: transform $iconTransitionDuration ease-in-out;
            transform-origin: right;
        }

        path {
            fill: var(--token-accent-theme-1-button-button-highlight) !important;
        }
    }

    &:hover,
    &:focus {
        border-color: var(--token-accent-theme-1-border-border-highlight);
    }

    &:focus-within {
        border-color: var(--token-accent-theme-1-border-border-highlight);
    }
}
