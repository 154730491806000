import { useState } from 'react';
import classNames from 'classnames';

import { pathToURL } from '@/utils/urls';
import { useTheme } from '@/theme';
import { Faction, Rank } from '@/models/profile';
import ImageWithLoading from '@/components/ImageWithLoading';

import * as styles from './styles.module.scss';

interface RankProps {
    classname?: string;
    faction: Faction;
    rank: Rank;
}

// const COLOURS = ['#FFFFFF', '#8FFF97', '#A7F0FE', '#E569FB', '#FD8917'];
const STAR_IMAGES = [
    '/assets/themes/titan/images/rank/stars1.webp',
    '/assets/themes/titan/images/rank/stars2.webp',
    '/assets/themes/titan/images/rank/stars3.webp',
];

const getStarImage = (starLevel: number) => {
    if (!starLevel) return STAR_IMAGES[0]; // If no level, return first image
    if (STAR_IMAGES[starLevel - 1]) return STAR_IMAGES[starLevel - 1]; // If correct image exists, return it
    if (starLevel > STAR_IMAGES.length) return STAR_IMAGES[STAR_IMAGES.length - 1]; // If star level somehow too high, return last image
    return STAR_IMAGES[0]; // Otherwise return first image
};

const extractNumberFromId = (id: string) => {
    // ID is format vanguard_3, survivor_2 etc.
    const match = id?.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 1;
};

const DEFAULT_COLOUR = '#FFFFFF';
const DEFAULT_RANK_LEVEL = 1;
const NUMBER_OF_STARS_WHEN_STARS_GET_COLOURED = 3;

export default function Rank({ classname, faction, rank }: RankProps) {
    const theme = useTheme();
    const [factionImageIsLoaded, setFactionImageIsLoaded] = useState(false);
    const [starImageIsLoaded, setStarImageIsLoaded] = useState(false);

    if (!theme.customJSON?.isRankEnabled || !faction?.rankImagePath || !STAR_IMAGES.length) {
        // Basic faction image
        return (
            <div className={classNames(styles.Rank, classname)}>
                <img
                    className={styles.fallbackImage}
                    src={pathToURL(faction.imagePath)}
                    alt={`Faction ${faction.name}`}
                />
            </div>
        );
    }

    const rankLevel = rank?.rankLevel ?? DEFAULT_RANK_LEVEL; // Rank level (e.g 13)
    const starLevel = extractNumberFromId(rank?.id); // How many stars to show (1, 2 or 3)
    const starImage = getStarImage(starLevel); // Image of stars
    const rankColour = rank?.rankColour ?? DEFAULT_COLOUR; // Colour
    const key = `${faction.id}-${rankLevel}`; // Unique key stops old images showing when going between different users

    const isLoading = !(factionImageIsLoaded && starImageIsLoaded);

    return (
        <div className={classNames(styles.Rank, classname, { [styles.isLoading]: isLoading })}>
            <span className={styles.label}>Rank: {rankLevel}</span>
            <div className={classNames(styles.RankBadge)} aria-label={`Faction ${faction.name}, Level ${rankLevel}`}>
                <ImageWithLoading
                    key={`${key}-1`}
                    className={styles.image}
                    src={pathToURL(faction.rankImagePath)}
                    alt=" "
                    aria-hidden
                    onLoad={() => {
                        setFactionImageIsLoaded(true);
                    }}
                />
                <div
                    key={`${key}-2`}
                    className={styles.overlay}
                    style={{
                        backgroundColor: rankColour,
                        maskImage: `url("${pathToURL(faction.rankImagePath)}")`,
                    }}
                ></div>
                <ImageWithLoading
                    key={`${key}-3`}
                    className={styles.image}
                    src={starImage}
                    alt=" "
                    aria-hidden
                    onLoad={() => {
                        setStarImageIsLoaded(true);
                    }}
                />
                {starLevel >= NUMBER_OF_STARS_WHEN_STARS_GET_COLOURED && (
                    <div
                        key={`${key}-4`}
                        className={styles.overlay}
                        style={{
                            backgroundColor: rankColour,
                            maskImage: `url("${starImage}")`,
                        }}
                    ></div>
                )}
            </div>
        </div>
    );
}
