import React from 'react';
import Markdown from 'markdown-to-jsx';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

const LinkRenderer = ({ href, children }: { href?: string; children: React.ReactNode }) => {
    if (!href) {
        return <span>{children}</span>;
    }

    return (
        <a href={href} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};

/* Accepts markdown and renders JSX. Uses a custom component to render anchors.
Comes with some default CSS, which can be stopped by setting hasDefaultStyling to false */

const MarkdownRenderer = ({
    markdownContent,
    hasDefaultStyling = true,
    textAlign = 'center',
}: {
    markdownContent: string;
    hasDefaultStyling?: boolean;
    textAlign?: 'center' | 'left';
}) => {
    return (
        <div
            className={classNames(styles.MarkdownRenderer, {
                [styles.hasDefaultStyling]: hasDefaultStyling,
                [styles.centerAlign]: textAlign === 'center',
                [styles.leftAlign]: textAlign === 'left',
            })}
        >
            <Markdown
                options={{
                    overrides: {
                        a: LinkRenderer,
                    },
                    disableParsingRawHTML: true,
                }}
            >
                {markdownContent}
            </Markdown>
        </div>
    );
};

export default MarkdownRenderer;
