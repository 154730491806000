@import '../../style/globals.scss';

.ItemContainer {
    $transition-duration: 400ms;

    position: relative;

    &:last-child {
        margin-right: 0px;
    }

    &__BackgroundContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        transition: filter $transition-duration $animation-bounce;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__BackgroundIcon {
        position: absolute;
        top: calc(50% - 15px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        fill: var(--token-accent-theme-1-interface-brand-interface-midground);
        transition: transform $transition-duration $animation-bounce;
    }

    &__Button {
        position: relative;
        // width: 203px;
        // height: 237px;
        width: 100%;
        aspect-ratio: 203 / 237;
        background-size: 100% 100%;

        &--interactable {
            &:hover {
                .ItemContainer {
                    &__Border {
                        border-color: var(--token-accent-theme-1-border-border-highlight);
                    }
                }
            }
            &:focus,
            &:hover {
                .ItemContainer {
                    &__Image {
                        transform: scale(1.2);
                    }

                    &__BackgroundIcon {
                        transform: translate(-50%, -50%) scale(1.2);
                    }

                    &__BackgroundContainer {
                        filter: brightness(1.2);
                    }
                }
            }
        }
    }

    &__Border {
        $outline-offset: 10px;
        position: absolute;
        width: calc(100% - ($outline-offset * 2));
        height: calc(100% - ($outline-offset * 2));
        border: 1.5px solid var(--token-accent-theme-1-border-border-default);
        margin: $outline-offset;
        inset: 0;
        transition: border-color 500ms $animation-bounce;
    }

    &__Image {
        position: relative;
        width: 50%;
        margin-right: auto;
        margin-left: auto;
        transition: transform $transition-duration $animation-bounce;
        user-select: none;
        pointer-events: none;
    }

    &__HighlightImage {
        position: absolute;
        z-index: -1;
        top: -135px;
        left: -15px;
        width: 224px;
        height: 491px;
        background-repeat: no-repeat;
        background-position-y: bottom;
        rotate: 30deg;
        transition: none;
        opacity: 0;
        background-size: 100% 0%;
    }

    &__Description {
        position: absolute;
        right: 0;
        bottom: -8px;
        left: 0;
        margin: 0px 8px;
    }

    @keyframes slide-in {
        from {
            bottom: -200px;
            opacity: 0;
        }
        to {
            bottom: 0px;
            opacity: 1;
        }
    }
}
