@import '../../style/globals.scss';

// Also referenced in AuthDrawer.tsx
$drawerImageWidth: 989px;
$drawerImageHeight: 1117px;
$defaultColour: var(--token-LEGACY-accent-theme-1-interface-interface-midground);

.AuthDrawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &__drawer {
        transition-property: margin-left;
        transition-timing-function: $animation-bounce;
        position: absolute;
        left: calc(-1 * (100% + $drawerImageWidth));
        width: calc(100% + $drawerImageWidth);
        height: 100%;

        &Left {
            display: inline-block;
            width: calc(100% - $drawerImageWidth);
            height: 100%;
            overflow: hidden;
        }

        &Right {
            position: relative;
            display: inline-block;
            overflow: hidden;
            width: $drawerImageWidth;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position-x: right;
        }

        // Responsive sizes of drawer, controlled by component prop
        &--none {
            margin-left: 0%;
        }
        &--small {
            margin-left: 594px;
            @media (max-width: $bp-s) {
                margin-left: calc(100% + 10px);
            }
        }
        &--mediumContent {
            margin-left: 864px;
            @media (max-width: $bp-s) {
                margin-left: calc(100% + 200px);
            }
        }
        &--largeContent {
            margin-left: $drawerImageWidth;
            @media (max-width: $bp-s) {
                // TODO work out value
            }
        }
        &--full {
            margin-left: calc(100% + $drawerImageWidth);
        }
    }

    &__contentLine {
        display: none;
    }

    // Default style if no image passed in
    &--noImage &__drawer {
        bottom: 0;
        height: 100%;
        min-height: $drawerImageHeight;
        clip-path: polygon(0% 0%, calc(100% - 400px) 0%, 100% 100%, 0% 100%);
        backdrop-filter: blur(10px);

        &Left,
        &Right {
            background-color: $defaultColour !important;
        }
    }

    &--noImage &__contentLine {
        position: absolute;
        z-index: 1;
        top: 65%;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
    }
}
