@import '../../../style/globals.scss';

$character-container-height: 500px; // Must match height of character container

.ProfileEditor {
    position: absolute;
    z-index: 10;
    top: -4px;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    pointer-events: none;

    .loadingScreen {
        height: 100%;
        top: 0;
    }

    .top {
        height: $character-container-height;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .bottom {
        position: absolute;
        top: $character-container-height;
        width: 100%;
        min-height: calc(100% - $character-container-height);
        background-color: black;
        margin: 0;
        padding: 0;
        pointer-events: all;
    }

    .editorContainer {
        @include border-image();
        position: relative;
        height: 100%;
        min-height: 500px;
        padding: 24px 50px;
        margin: -10px auto 0px auto;
        background-size: 100% 100%;
        transition: height 0.5s linear;
        max-width: 430px;

        @media (max-width: $bp-mobile-chat) {
            max-width: 500px;
        }

        @media (max-width: $bp-xxs) {
            padding: 12px 12px;
        }
    }

    .tabButtons {
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        li {
            width: 40px;
            height: 40px;
        }

        button {
            width: 100%;
            height: 100%;

            svg {
                width: 100%;
                height: 100%;
            }

            svg path {
                fill: var(--token-accent-theme-1-button-button-secondary-inactive);
            }

            &.isActive {
                svg path {
                    fill: var(--token-accent-theme-1-button-button-secondary-active);
                }
            }

            &:hover,
            &:focus {
                svg path {
                    fill: var(--token-accent-theme-1-button-button-secondary-highlight);
                }
            }
        }
    }

    .title {
        position: absolute;
        top: 41px;
        left: 50%;
        font-family: var(--font-primary);
        font-size: $font-size-h6;
        text-align: center;
        transform: translateX(-50%);
    }

    .tabTitle {
        text-align: center;
        font-size: $font-size-h6;
        font-family: var(--font-primary);
        margin: 20px 0px;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        gap: 10px;

        .empty {
            width: 100%;
            text-align: center;
        }

        li {
            width: calc(50% - 5px);
        }

        .isCurrent {
            box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.3);
        }

        .titleItem {
            width: 100%;
        }

        .factionItem {
            img {
                margin-bottom: 30px;
            }
        }

        .frameItem {
            img {
                width: calc(100% - 40px);
                margin-bottom: 25px;
            }
        }

        .characterItem {
            img {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px;
            }
        }

        .backgroundItem {
            img {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px 20px 30px 20px;
            }
        }
    }
}
