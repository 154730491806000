@import '../../style/globals.scss';

.CardDescription {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    padding: 5px 15px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: left;
    mask-size: 100% 100%;
    transition: transform 200ms $animation-bounce;

    // Default rarity
    background-color: var(--token-arise-theme-1-rarity-rarity-common-dark);

    .subtitle {
        color: var(--token-arise-theme-1-rarity-rarity-common-light);
    }

    @each $rarity in $rarities {
        &.#{$rarity} {
            background-color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-dark);

            .subtitle {
                color: var(--token-arise-theme-1-rarity-rarity-#{$rarity}-light);
            }
        }
    }

    &.noBackgroundColour {
        background-color: transparent;
    }

    &.isButton {
        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    .title {
        font-size: $font-size-p;
        font-family: var(--font-tertiary);
        font-weight: 500;

        &.longTitle {
            font-size: $font-size-tiny;
        }

        @media (max-width: $bp-xs) {
            font-size: $font-size-small;

            &.longTitle {
                font-size: $font-size-tiny;
            }
        }
    }

    .subtitle {
        font-size: $font-size-tiny;
        font-weight: 300;
    }

    .icon {
        position: absolute;
        top: 4px;
        right: 6px;
        width: 24px;
        height: 24px;
        fill: var(--token-accent-theme-1-text-text-default);
        opacity: 0.7;
    }
}
