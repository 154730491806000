@import '../../../style/globals.scss';

.ProfileAssetsContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;

    .loadingScreen {
        background-color: black;
        transition: none;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .character {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
        pointer-events: none;
    }

    .avatar {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 40px;
        height: 40px;
        transform: translateX(-50%);
        pointer-events: none;
    }
}
