import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as styles from '../styles.module.scss';

import { AuthDrawer, authDrawerWidth } from '@/components/AuthDrawer';

import Input from '@/components/Forms/Input';
import Button from '@/components/Forms/Button';
import { useTheme } from '@/theme';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { register, login } from '@/state/features/auth';
import { useAudio } from '@/hooks/audio';
import { isValidEmail } from '@/utils/validation';

const loginPath = process.env.PUBLIC_INSTANCE === 'titan' ? '/auth/arise/login' : '/auth/login';

export default function RegisterPage() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { audio } = useAudio();

    const navigate = useNavigate();

    const auth = useAppSelector((state) => state.auth);

    useEffect(() => {
        audio?.playOnly('drone-bg');
    }, []);

    function onSubmit() {
        audio?.play('button-click');

        if (auth.isRegistering) return;

        // TODO: Validate password
        if (!isValidEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }
        if (!email || !username || !password) return;
        dispatch(register([username, email, password]));
    }

    useEffect(() => {
        if (auth.isRegistrationSuccessful) {
            dispatch(login([email, password]));
        }
    }, [auth.isRegistrationSuccessful]);

    useEffect(() => {
        if (auth.loggedIn && auth.isRegistrationSuccessful) {
            audio?.play('gust-open');
            navigate('/edit-profile');
        }
    }, [auth.loggedIn]);

    useEffect(() => {
        if (auth.registerError) {
            setErrorMessage(auth.registerError);
        }
    }, [auth.registerError]);

    if (auth.loggedIn) {
        return <h1>Welcome. Loading...</h1>;
    }

    const renderDrawerContent = () => {
        return (
            <div className={styles.registerPage__content}>
                <div
                    aria-label={theme.title}
                    className={styles.registerPage__logo}
                    style={{ backgroundImage: `url(${theme.files.instance_mark})` }}
                ></div>
                <div className={styles.registerPage__formContent}>
                    <h2 className="text-[14px] pb-[1em] font-bold">Register</h2>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Address"
                        style={{ marginBottom: 14, width: '100%' }}
                        onSubmit={onSubmit}
                        autoComplete="email"
                    />
                    <br />
                    <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        style={{ marginBottom: 14, width: '100%' }}
                        onSubmit={onSubmit}
                        autoComplete="username"
                    />
                    <br />
                    <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        style={{ marginBottom: 14, width: '100%' }}
                        onSubmit={onSubmit}
                        type="password"
                        autoComplete="new-password"
                    />
                    <Button onClick={onSubmit} onSubmit={onSubmit} className={styles.authNextStepButton}>
                        {auth.isRegistering ? 'Registering...' : 'Register'}
                    </Button>
                    <p
                        className="text-[14px] pt-[14px]"
                        style={{
                            color: theme.tokens.AccentTheme1TextTextDefault,
                        }}
                    >
                        <Link
                            style={{
                                color: theme.tokens.AccentTheme1TextTextSecondary,
                            }}
                            to={loginPath}
                        >
                            Back to Login
                        </Link>
                    </p>
                    <p className={styles.registerPage__errorMessage}>{errorMessage}</p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.registerPage}>
            <AuthDrawer
                imagePath={theme.customJSON.loginDrawerImage}
                width={authDrawerWidth.mediumContent}
                imageColour={theme.customJSON.loginDrawerColour}
                animationDurationMs={0}
            />
            {renderDrawerContent()}
        </div>
    );
}
