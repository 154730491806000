import { BP_MOBILE_CHAT } from '@/globals';

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
export function isTouchDevice() {
    // @ts-ignore
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export function isMobileWidth() {
    return window.innerWidth <= BP_MOBILE_CHAT;
}
