import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullpageWrapper from '@/components/FullpageWrapper';
import SceneSelector from '@/components/SceneSelector';
import LoadingScreen from '@/components/LoadingScreen';
import FloatingHeaderBar from '@/components/FloatingHeaderBar';
import ExitModal from '@/components/ExitModal';
import NewsModal from '@/components/NewsModal';
import GuestSignUpModal from '@/components/GuestSignUpModal';
import { useAppSelector, useAppDispatch } from '@/state/store';
import { loadLocations, loadSceneSelectorData, setHasFailed } from '@/state/features/app';
import { useLocations } from '@/hooks/location';
import { useTheme } from '@/theme';
import { useAudio } from '@/hooks/audio';
import { transitionStates, pageTransitionTime } from '@/utils/pageTransition';

export default function SceneSelectorPage() {
    const { isLoading: areLocationsLoading, locations } = useLocations();
    const theme = useTheme();
    const { audio } = useAudio();
    const sceneSelectorData = useAppSelector((state) => state.app.sceneSelectorData);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [selectorTransitionState, setSelectorTransitionState] = useState(transitionStates.preVisible);
    const [areParallaxImagesLoaded, setAreParallaxImagesLoaded] = useState(false);

    const shouldShowLoadingScreen = !theme.themeId || !sceneSelectorData || !locations;

    useEffect(() => {
        dispatch(loadSceneSelectorData());
        if (areLocationsLoading) return;
        dispatch(loadLocations());

        // We set has failed to false here to reset the state if the user navigates back to this page.
        // Since the death state is only shown for 5 to 10 seconds it's not an issue if someone tries to circumvent it.
        dispatch(setHasFailed(false));
    }, []);

    useEffect(() => {
        if (!areLocationsLoading && areParallaxImagesLoaded && !shouldShowLoadingScreen) {
            setSelectorTransitionState(transitionStates.visible);
        }
    }, [areLocationsLoading, areParallaxImagesLoaded, shouldShowLoadingScreen]);

    const scenesData = useMemo(() => {
        if (!locations) return [];
        return sceneSelectorData?.scenes.map((scene) => {
            const matchingLocation = locations[scene.environmentID];
            return {
                ...scene,
                isOpen: matchingLocation?.isOpen,
            };
        });
    }, [locations, sceneSelectorData]);

    const navigateToScene = (locationId: string) => {
        // Allow superadmins to access location when closed
        if (locations[locationId]?.isOpen || currentUserProfile?.role === 'superadmin') {
            setSelectorTransitionState(transitionStates.postVisible);
            audio.play('slam');
            setTimeout(() => {
                navigate('/location/' + locationId);
            }, pageTransitionTime);
        }
    };

    if (shouldShowLoadingScreen) return <LoadingScreen show={true} />;

    return (
        <FullpageWrapper>
            <SceneSelector
                transitionState={selectorTransitionState}
                scenes={scenesData}
                sceneSelectorSettings={sceneSelectorData}
                onSceneSelected={(locationId) => navigateToScene(locationId)}
                onParallaxLoaded={() => setAreParallaxImagesLoaded(true)}
            />
            <ExitModal />
            <FloatingHeaderBar />
            <GuestSignUpModal />
            <NewsModal />
        </FullpageWrapper>
    );
}
