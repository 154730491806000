import classNames from 'classnames';

import { Profile } from '@/models/profile';
import { pathToURL } from '@/utils/urls';
import Badge from '@/components/Badge';
import DisplayName from '@/components//DisplayName';

import * as styles from './styles.module.scss';

interface DisplayNameProps {
    profile: Profile;
    shouldLinkToProfile?: boolean;
    displayNameClassname?: string;
    hasFaction?: boolean;
}

export default function DisplayNameWithBadges({
    profile,
    shouldLinkToProfile,
    displayNameClassname,
    hasFaction = true,
}: DisplayNameProps) {
    const renderBadge = () => {
        let iconImage = profile?.faction.iconImageUrl;
        if (!iconImage) {
            iconImage = pathToURL(profile?.faction.iconPath);
        }

        return (
            <div className={styles.badgeContainer}>
                {profile?.role === 'superadmin' && (
                    <Badge text="Moderator" isSystemBadge={true} classname={styles.systemBadge} />
                )}
                {profile?.title?.title && (
                    <Badge
                        text={profile.title.title}
                        rarity={profile.title.rarity}
                        classname={styles.titleBadge}
                        title={profile.title.rarity?.toString()}
                    />
                )}
                {hasFaction && profile?.role !== 'superadmin' && (
                    <Badge text={profile?.faction.name} rarity={profile?.title?.rarity} iconImageUrl={iconImage} />
                )}
            </div>
        );
    };

    return (
        <div className={styles.DisplayNameWithBadges}>
            <DisplayName
                profile={profile}
                shouldLinkToProfile={shouldLinkToProfile}
                classname={classNames(styles.displayName, displayNameClassname)}
            />
            {renderBadge()}
        </div>
    );
}
