@import '../../style/globals.scss';

.Feed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: var(--font-tertiary);
}

.messages {
    flex: 1;
    position: relative;
    overflow: hidden;
    @include scrollbar-styles;
}

.input {
    // TODO - Does not align with chat when scrollbar present
    margin: 0px $chat-padding-m $chat-padding-m $chat-padding-m;
    position: relative;
    z-index: 100;

    @media (max-width: $bp-s) {
        margin: 0px $chat-padding-s $chat-padding-s $chat-padding-s;
    }

    @media (max-width: $bp-xs) {
        margin: 0px $chat-padding-xs $chat-padding-xs $chat-padding-xs;
    }
}

.scrollToBottom {
    position: absolute;
    right: $chat-padding-m;
    bottom: -50px;
    width: 40px !important;
    height: 40px !important;
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-white) !important;
    z-index: 100;
    transition-duration: 300ms;
    transition-property: bottom;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    visibility: hidden;

    svg path {
        fill: var(--token-LEGACY-accent-theme-1-interface-interface-black) !important;
    }

    @media (max-width: $bp-s) {
        right: $chat-padding-s;
    }

    @media (max-width: $bp-xs) {
        right: $chat-padding-xs;
    }

    &:hover {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-grey) !important;
    }

    &.show {
        bottom: 26px;
        transition-delay: 0.5s;
        visibility: visible;
    }
}

.FeedContentNew {
    height: 100%;
    width: 100%;
    flex: 1;
    overflow: hidden scroll;
}

.FeedVirtual {
    height: 100%;
    width: 100%;
    overflow: hidden scroll;
    @include scrollbar-styles;
}
.slider {
    width: 100%;
    position: relative;
}

.itemHolder {
    position: absolute;
    width: 100%;
}

.loadingSpinner {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 100%;
}

.firstItem {
    position: absolute;
    top: 0;
    width: 100%;
}

.loadMoreButton {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 0 10px;
    line-height: 32px;
    font-size: $font-size-p;
    font-weight: bold;
    border-radius: 16px;
    cursor: pointer;
}

.vw-list {
    @include scrollbar-styles;
}

.feedItem {
    padding: 0 $chat-padding-m;

    @media (max-width: $bp-s) {
        padding: 0 $chat-padding-s;
    }

    @media (max-width: $bp-xs) {
        padding: 0 $chat-padding-xs;
    }
}
