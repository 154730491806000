@import '../../style/globals.scss';

.NewsModal {
    height: auto;

    .content {
        padding: 40px;

        @media (max-width: $bp-xs) {
            padding: 20px;
        }
    }

    img {
        width: 100%;
        padding: 15px 0px;
    }
}
