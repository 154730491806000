@import '../../style/globals.scss';

.Modal {
    width: 700px;
    max-width: calc(100% - 40px);
    height: 100vh; // Make modal max size its allowed
}

.SupplyRunLeaderboardTable {
    position: relative;
    font-size: $font-size-p;

    @media (max-width: $bp-xxs) {
        font-size: $font-size-small;

        .isLong {
            font-size: $font-size-tiny;
        }
    }

    .empty {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        margin: 20px 0px;

        svg {
            display: inline;
            height: 80px;
            opacity: 0.3;
        }
    }

    &.Today {
        tr.first {
            background-color: var(--token-arise-theme-1-rarity-rarity-epic-dark);
        }
    }

    &.AllTime {
        tr.first {
            background-color: var(--token-arise-theme-1-rarity-rarity-uncommon-dark);
        }
    }

    .userStats {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        min-height: 132px;
        padding: 5px;
        margin-bottom: 22px;
        background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
        border-radius: 10px;

        .profile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .avatar {
                position: relative;
                width: 70px;
                height: 70px;
                margin: 10px;

                @media (max-width: $bp-xxs) {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .stats {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            @media (max-width: $bp-xxs) {
                svg {
                    width: 30px;
                }
            }

            @media (max-width: $bp-xs) {
                flex-direction: column;
                align-items: flex-start;
                gap: 0px;
            }
        }
    }

    .tabButtons {
        margin-top: 12px;
        margin-bottom: 22px;

        button {
            display: inline-block;
            width: 78px;
            padding: 2px 0px;
            background-color: var(--token-accent-theme-1-button-button-secondary-inactive);
            color: var(--token-accent-theme-1-text-text-default);
            text-align: center;
            border-radius: 20px;

            &:first-child {
                margin-right: 10px;
            }

            &:focus,
            &:hover {
                filter: brightness(1.2);
            }

            &.isCurrent {
                background-color: var(--token-accent-theme-1-button-button-secondary-active);
                color: var(--token-accent-theme-1-text-text-contrast-default);

                &:focus,
                &:hover {
                    filter: none;
                }
            }
        }
    }

    .user {
        gap: 20px;
        display: flex;

        .avatar {
            display: inline-block;
            width: 40px;
            height: 40px;
        }

        @media (max-width: $bp-xs) {
            .avatar {
                width: 30px;
                height: 30px;
            }
        }
    }

    .positionTitle {
        padding-left: 8px;
    }

    .position {
        padding-left: 10px;
        padding-right: 5px;
    }

    .userTitle {
        padding-left: 5px;
    }

    @media (max-width: $bp-xxs) {
        .positionTitle {
            padding-left: 3px;
        }
        .position {
            padding-left: 5px;
        }
    }

    table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    h3 {
        font-family: var(--font-primary);
        color: var(--token-accent-theme-1-text-text-default);
        font-size: $font-size-h6;
    }

    td {
        padding: 2px;
    }

    tr {
        background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
    }

    thead {
        tr {
            background: none;
        }
    }

    th {
        font-size: $font-size-small;
        opacity: 0.3;
        font-weight: 300;
    }

    td,
    th {
        text-align: left;
    }
}
