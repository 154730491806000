import { Profile } from '@/models/profile';
import { useAppSelector } from '@/state/store';

export function useUserProfile(userId: string) {
    const profile = useAppSelector((state) => state.profile.profileByUserId[userId]) as Profile | undefined;

    return profile;
}

export function useCurrentUserIsGuest() {
    const role = useAppSelector((state) => state.profile.currentUserProfile?.role);
    return role === 'guest';
}
