import { LinkButton } from '@/components/Forms/Button';
import { guestRegistrationPath } from '@/theme/vars';
import { sendEvent } from '@/utils/analytics';
import { useTheme } from '@/theme';

import * as styles from './styles.module.scss';

export default function GuestInputOverlay() {
    const FALLBACK_TEXT = 'Sign Up to join the conversation and get the best experience.';
    const theme = useTheme();
    const text = theme.customJSON.guestFeedInputOverlayText ?? FALLBACK_TEXT;
    return (
        <div className={styles.GuestFeedInputOverlay}>
            <span>{text}</span>
            <LinkButton
                className={styles.loginButton}
                href={guestRegistrationPath}
                onClick={() =>
                    sendEvent('guest_chat_overlay_sign_up_click', {
                        message: 'Sign Up to join the conversation and get the best experience.',
                    })
                }
            >
                Sign Up
            </LinkButton>
        </div>
    );
}
