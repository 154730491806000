import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@/state/store';
import { sendEvent } from '@/utils/analytics';

import * as styles from './styles.module.scss';

export default function LocationLink({ locationId, style }: { locationId: string; style?: React.CSSProperties }) {
    const locations = useAppSelector((state) => state.app.locations);
    const currentLocationId = useAppSelector((state) => state.app.location?.id);
    const roomId = useAppSelector((state) => state.feed.roomId);
    const navigate = useNavigate();

    if (locationId !== roomId) {
        const itemLocation = locations[locationId];
        if (itemLocation) {
            return (
                <div
                    style={style}
                    className={styles.LocationLink}
                    onClick={() => {
                        sendEvent('global_chat_link_click', { destination: itemLocation.id, from: currentLocationId });
                        navigate(`/location/${itemLocation.id}`);
                    }}
                    title={`Go to ${itemLocation.name} to join this chat!`}
                >
                    #{itemLocation.name}
                </div>
            );
        }
    }
    return null;
}
