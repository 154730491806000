import classNames from 'classnames';

import CardDescription from '@/components/CardDescription';
import Icon, { IconTypes } from '@/components/Icon';

import * as styles from './styles.module.scss';

interface ItemContainerProps {
    classname?: string;
    imagePath?: string;
    backgroundIconType?: IconTypes;
    backgroundImagePath: string;
    textBackgroundImagePath: string;
    title: string;
    subtitle?: string;
    highlightImagePath?: string;
    isSelected?: boolean;
    onSelected?: () => void;
    imageAlt?: string;
    interactable?: boolean;
    hasBorder?: boolean;
}

export default function ItemContainer({
    classname,
    imagePath,
    backgroundImagePath,
    textBackgroundImagePath,
    highlightImagePath,
    title,
    subtitle,
    isSelected,
    onSelected,
    imageAlt,
    interactable,
    hasBorder = true,
    backgroundIconType,
}: ItemContainerProps) {
    const highlightImageStyle = highlightImagePath ? { backgroundImage: `url(${highlightImagePath})` } : {};

    return (
        <div className={classNames(styles.ItemContainer, classname)}>
            <button
                className={classNames(styles.ItemContainer__Button, {
                    [styles['ItemContainer__Button--interactable']]: interactable,
                })}
                onClick={() => onSelected && onSelected()}
                disabled={!interactable}
                aria-selected={isSelected}
            >
                <div
                    className={styles.ItemContainer__BackgroundContainer}
                    style={{ backgroundImage: `url("${backgroundImagePath}")` }}
                >
                    {backgroundIconType && (
                        <Icon className={styles.ItemContainer__BackgroundIcon} type={backgroundIconType} />
                    )}
                </div>
                {hasBorder && <div className={styles.ItemContainer__Border}></div>}
                <img src={imagePath} className={styles.ItemContainer__Image} alt={imageAlt} />
                <CardDescription
                    classname={styles.ItemContainer__Description}
                    title={title}
                    subtitle={subtitle}
                    backgroundImage={textBackgroundImagePath}
                />
            </button>
            {highlightImagePath && (
                <div className={styles.ItemContainer__HighlightImage} style={highlightImageStyle}></div>
            )}
        </div>
    );
}
