import classNames from 'classnames';
import { useEffect } from 'react';
import { useAppSelector } from '@/state/store';

import * as styles from './styles.module.scss';

export interface FullSizeBackgroundProps {
    imagePath: string;
    className?: string;
    blur?: number;
    zoom?: number;
    translate?: string;
    onImageLoaded?: () => void;
    style?: Omit<React.CSSProperties, 'backgroundImage' | 'filter' | 'transform' | 'transformOrigin'>;
    brightness?: number;
    transformOrigin?: string;
}

export default function FullSizeBackground({
    imagePath,
    className,
    blur = 0,
    zoom = 1,
    onImageLoaded,
    style,
    translate = '0,0',
    brightness = 1,
    transformOrigin = 'center',
}: FullSizeBackgroundProps) {
    const app = useAppSelector((state) => state.app);

    useEffect(() => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
            if (onImageLoaded) onImageLoaded();
        };
    }, [imagePath, onImageLoaded]);

    const shouldBlur = app.isInventoryOpen || app.isExitLocationModalOpen || app.isSupplyRunLeaderboardOpen;

    const transform = `scale(${zoom}) translate(${translate})`;

    return (
        <div
            className={classNames(styles.Sidebar, 'w-full h-full absolute top-0 left-0 bg-center bg-cover', className)}
            style={{
                ...style,
                backgroundImage: `url(${imagePath})`,
                filter: `blur(${blur}px) brightness(${brightness})`,
                transform,
                transformOrigin,
            }}
        >
            <div
                className={
                    'w-full h-full absolute duration-300 transition-[backdrop-filter] ' +
                    (shouldBlur && 'backdrop-blur-md')
                }
            ></div>
        </div>
    );
}
