import { useRef } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { setIsSidebarOpen } from '@/state/features/app';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';
import { isMobileWidth } from '@/utils/mobile';
import IconButton from '@/components/IconButton';

export default function ChatWidget() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();

    const isFeedOpen = useAppSelector((state) => state.app.location.isFeedOpen);
    const buttonHasBeenClickedOnce = useRef(false);

    const openChat = () => {
        audio?.play('gust-open');
        dispatch(setIsSidebarOpen(true));
        sendEvent('chat_open');

        if (!buttonHasBeenClickedOnce.current) {
            buttonHasBeenClickedOnce.current = true;
        }
    };

    return (
        <IconButton
            onClick={openChat}
            ariaLabel="Open chat"
            buttonStyle="round"
            iconType="chat"
            hasDot={!buttonHasBeenClickedOnce.current && isFeedOpen && isMobileWidth()}
        />
    );
}
