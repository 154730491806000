import { checkoutShopItem, getAssetPacks, getSessionStatus } from '@/arise/api';
import { SessionStatusResponse } from '@/models/shop';

export async function purchaseAssetPack(itemID: string): Promise<boolean> {
    // Returns if is successful or not
    try {
        const response = await checkoutShopItem(itemID, 'asset_pack');
        if (response.stripeURL) {
            window.location.href = response.stripeURL;
            return true;
        }
    } catch (error) {
        console.warn('Error calling checkout for item ID:', itemID, error);
        return false;
    }
}

export async function pollSessionStatus(sessionId: string, timeoutMs: number = 60000) {
    const startTime = Date.now();

    try {
        let response: SessionStatusResponse;
        do {
            console.log('Polling for session status');
            response = await getSessionStatus(sessionId);

            if (response.status === 'confirmed') {
                return response;
            }
            if (response.status !== 'pending') {
                throw new Error('Unrecognised session status');
            }

            // Check if the timeout has been exceeded
            if (Date.now() - startTime >= timeoutMs) {
                console.warn('Polling timed out');
                return null;
            }

            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        } while (response.status === 'pending');
    } catch (error) {
        console.error('Could not get session status ', error?.message);
        throw error;
    }
}

export async function getAssetPackById(id: string) {
    try {
        const packs = await getAssetPacks();
        return packs.find((pack) => pack.id === id);
    } catch (error) {
        console.error('Could not load packs');
    }
}
