import { useEffect, useRef, useState, TransitionEvent } from 'react';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { useTheme } from '@/theme';
import { InventoryItem } from '@/models/Inventory';
import { AriseBridgeService } from '@/arise/bridge/AriseBridgeService';
import {
    loadInventory,
    setIsInventoryUsingItem,
    setIsSnackbarVisible,
    setIsUnseenItemInInventory,
} from '@/state/features/app';
import { setIsInventoryOpen } from '@/state/features/app';
import IconButton from '@/components/IconButton';
import ItemContainer from '@/components/ItemContainer';

import * as styles from './styles.module.scss';
import { H6 } from '@/components/typography/Heading';
import { P } from '@/components/typography/Paragraph.tsx';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';

export default function InventoryDrawer() {
    const { isInventoryOpen, isInventoryUsingItem } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const prevIsInventoryOpenRef = useRef(false);
    const [isOpenTransitionComplete, setIsOpenTransitionComplete] = useState(false);

    const { audio } = useAudio();

    // Reset the using item state when the inventory closes
    // This is delayed so that can't see the flash of text changing while the inventory is closing
    useEffect(() => {
        let timeout: any = 0;
        if (!isInventoryOpen) {
            setIsOpenTransitionComplete(false);
            timeout = setTimeout(() => {
                dispatch(setIsInventoryUsingItem(false));
            }, 500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isInventoryOpen]);

    useEffect(() => {
        // To stop sound playing when UI is reset etc
        if (isInventoryOpen && prevIsInventoryOpenRef.current !== true) {
            audio?.play('inventory-open');
        } else if (!isInventoryOpen && prevIsInventoryOpenRef.current !== false) {
            audio?.play('inventory-close');
        }
        prevIsInventoryOpenRef.current = isInventoryOpen;
    }, [isInventoryOpen]);

    // To move focus to close button when opened
    function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
        if (isInventoryOpen && e.target === e.currentTarget) {
            setIsOpenTransitionComplete(true);
        }
    }

    return (
        <>
            <div
                className="absolute top-0 left-0 w-full h-full"
                style={{
                    transition: 'backdrop-filter 300ms, visibility 300ms',
                    visibility: isInventoryOpen ? 'visible' : 'hidden',
                    backdropFilter: isInventoryOpen ? 'blur(10px)' : 'blur(0px)',
                }}
            ></div>
            <div
                className={classNames(styles.InventoryDrawer, {
                    [styles['InventoryDrawer__visible']]: isInventoryOpen,
                })}
                onTransitionEnd={onTransitionEnd}
            >
                <Inventory
                    visible={isInventoryOpen}
                    usingItem={isInventoryUsingItem}
                    shouldFocusOnCloseButton={isOpenTransitionComplete}
                />
            </div>
        </>
    );
}

interface InventoryProps {
    visible: boolean;
    usingItem: boolean;
    shouldFocusOnCloseButton: boolean;
}

function Inventory({ visible, usingItem, shouldFocusOnCloseButton }: InventoryProps) {
    const ITEMS_PER_PAGE = 4;

    const theme = useTheme();

    const dispatch = useAppDispatch();
    const { inventory } = useAppSelector((state) => state.app);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const previousButtonRef = useRef<HTMLButtonElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);
    const [currentPage, setCurrentPage] = useState(0);

    function closeInventoryWithoutSelection() {
        dispatch(setIsInventoryOpen(false));
        dispatch(setIsSnackbarVisible(true));
        AriseBridgeService.sendEvent('inventory_closed_without_selection', {});
        sendEvent('inventory_close');
    }

    useEffect(() => {
        // Close when user presses escape
        function onKeyUp(e: any) {
            if (e.key === 'Escape') {
                closeInventoryWithoutSelection();
            }
        }

        if (visible) {
            document.body.addEventListener('keyup', onKeyUp);
            return () => {
                document.body.removeEventListener('keyup', onKeyUp);
            };
        }
    }, [visible]);

    useEffect(() => {
        if (shouldFocusOnCloseButton) {
            setTimeout(() => closeButtonRef.current?.focus(), 200);
        }
    }, [shouldFocusOnCloseButton]);

    useEffect(() => {
        const remove1 = AriseBridgeService.on('inventory_takeItem_request__success', () => {
            dispatch(setIsUnseenItemInInventory(true));
            dispatch(loadInventory());
        });

        const remove2 = AriseBridgeService.on('inventory_dropItem_request__success', () => {
            dispatch(loadInventory());
        });

        return () => {
            remove1();
            remove2();
        };
    }, []);

    function handleClickItem(item: InventoryItem, index: number) {
        if (usingItem) {
            AriseBridgeService.sendEvent('inventory_item_selected', item);
            dispatch(setIsInventoryOpen(false));
            dispatch(setIsSnackbarVisible(true));
        }
    }

    function renderInfo() {
        if (usingItem) {
            return (
                <>
                    <H6>USE AN ITEM?</H6>
                    <P>Select an item to use</P>
                </>
            );
        }

        return (
            <>
                <H6>YOUR INVENTORY</H6>
                <P>Your Collected Items</P>
            </>
        );
    }

    function renderItem(item?: InventoryItem, i?: number) {
        return (
            <div className={styles.box} key={item?.id || i} onClick={item && (() => handleClickItem(item, i))}>
                {item && (
                    <ItemContainer
                        title={item.name}
                        imagePath={item.inventoryThumbnailUrl}
                        subtitle=""
                        backgroundImagePath={theme.customJSON?.cardBackgroundImageUrl}
                        textBackgroundImagePath={theme.customJSON?.cardTextBackgroundImageUrl}
                        imageAlt={`Inventory Item: ${item.name}`}
                        interactable={usingItem && visible}
                    />
                )}
                {!item && (
                    <ItemContainer
                        interactable={false}
                        title=""
                        imagePath="/assets/themes/titan/images/inventory/inventoryItem_empty.webp"
                        subtitle=""
                        backgroundImagePath={theme.customJSON?.cardBackgroundImageUrl}
                        textBackgroundImagePath=""
                    />
                )}
            </div>
        );
    }

    const currentPageItems = inventory.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);
    const numberOfPages = inventory.length ? Math.ceil(inventory.length / ITEMS_PER_PAGE) : 1;

    const changePage = (direction: number) => {
        const newPage = currentPage + direction;
        // If button is focussed but is about to become hidden, move focus to other button.
        const wasFocussedOnPreviousButton = document.activeElement === previousButtonRef.current;
        const wasFocussedOnNextButton = document.activeElement === nextButtonRef.current;
        if (newPage >= 0 && newPage < numberOfPages) {
            setCurrentPage(newPage);
        }
        if (newPage === 0 && wasFocussedOnPreviousButton) {
            setTimeout(() => nextButtonRef?.current?.focus(), 0);
        }
        if (newPage === numberOfPages - 1 && wasFocussedOnNextButton) {
            setTimeout(() => previousButtonRef?.current?.focus(), 0);
        }
    };

    return (
        <div
            aria-hidden={!visible}
            aria-live="polite"
            inert={!visible ? '' : undefined}
            className={styles.Inventory}
            style={{
                backgroundImage: `url("${theme.files.inventory_backdrop}")`,
                borderImageSource: `url("${theme.files.inventory_backdrop}")`,
            }}
        >
            <div className={styles.info}>{renderInfo()}</div>

            <IconButton
                iconType="close"
                buttonStyle="round"
                className={styles.closeButton}
                ariaLabel="Close Inventory"
                onClick={() => {
                    closeInventoryWithoutSelection();
                }}
                disabled={!visible}
                buttonRef={closeButtonRef}
            />
            <div className={styles.items}>
                {currentPageItems.map(renderItem)}
                {currentPageItems.length < ITEMS_PER_PAGE &&
                    new Array(ITEMS_PER_PAGE - currentPageItems.length).fill(undefined).map(renderItem)}
                <div className={styles.pageControls}>
                    {currentPage > 0 && (
                        <IconButton
                            iconType="play"
                            buttonStyle="icon"
                            className={styles.previousButton}
                            ariaLabel="Previous Inventory Page"
                            onClick={() => {
                                changePage(-1);
                            }}
                            disabled={!visible}
                            buttonRef={previousButtonRef}
                        />
                    )}
                    <div className={styles.pageNumber} aria-label={`Page ${currentPage + 1} of ${numberOfPages}`}>
                        {currentPage + 1} / {numberOfPages}
                    </div>
                    {currentPage + 1 < numberOfPages && (
                        <IconButton
                            iconType="play"
                            buttonStyle="icon"
                            className={styles.nextButton}
                            ariaLabel="Next Inventory Page"
                            onClick={() => {
                                changePage(1);
                            }}
                            disabled={!visible}
                            buttonRef={nextButtonRef}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
