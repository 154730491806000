import { useAppSelector } from '@/state/store';
import Icon from '@/components/Icon';
import MarkdownRenderer from '@/components/MarkdownRenderer';

import * as styles from './styles.module.scss';

export default function PinnedMessage() {
    const customJSON = useAppSelector((state) => state.app.location?.customJSON);
    const { title, markdown, imageUrl, isVisible } = customJSON.pinnedMessage || {};

    if (!isVisible || (!title && !markdown)) return null;

    return (
        <div className={styles.PinnedMessage}>
            <div className={styles.message}>
                <div className={styles.badge}>
                    <Icon type="pin" />
                    Pinned
                </div>
                <div className={styles.text}>
                    {title && <h3 className={styles.title}>{title}</h3>}
                    {markdown && (
                        <MarkdownRenderer textAlign="left" markdownContent={markdown} hasDefaultStyling={false} />
                    )}
                </div>
                {imageUrl && <div className={styles.image} style={{ backgroundImage: `url(${imageUrl})` }}></div>}
            </div>
        </div>
    );
}

/* 
Example customJSON inside location:
"pinnedMessage": {
    "imageUrl": "test.com/webp",
    "markdown": "##Pinned! \n\n Hello **bold text** [This a link](https://www.test.com)",
    "title": "Pinned Message",
    "isVisible": true
} 
*/
