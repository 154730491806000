@import '../../../style/globals.scss';

.ProfileCharacter {
    position: relative;

    div {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center bottom;
    }
}
