import * as arise from '@/arise/api';
import { Profile, ProfileAssetType, Rarity, Title } from '@/models/profile';
import { msTimeDifferenceFromNow } from './time';
import { pathToURL } from './urls';

// TODO: remove pathToURL once backend supplies urls.
export function processProfileData(profile: Profile) {
    return {
        ...profile,
        factionImageUrl: pathToURL(profile.faction.imagePath),
        faction: {
            ...profile.faction,
            iconImageUrl: pathToURL(profile.faction.iconPath),
        },
        isBanned: profile.bannedUntil && msTimeDifferenceFromNow(profile.bannedUntil) > 0,
    };
}

export function processTitles(titles: Title[]): Title[] {
    // Remove duplicates based on the title property
    const uniqueTitlesMap = new Map<string, Title>();
    titles?.forEach((title) => {
        uniqueTitlesMap.set(title.title, title);
    });

    // Convert the map back to an array
    const uniqueTitles = Array.from(uniqueTitlesMap.values());

    // Define the order of rarities
    const rarityOrder: Record<Rarity, number> = {
        Common: 1,
        Uncommon: 2,
        Rare: 3,
        Epic: 4,
        Legendary: 5,
    };

    // Sort the unique titles based on the rarity order
    uniqueTitles.sort((a, b) => rarityOrder[a.rarity] - rarityOrder[b.rarity]);

    return uniqueTitles;
}

export async function setProfileAsset(type: ProfileAssetType, itemId: string) {
    try {
        await arise.setUserCurrentAsset(type, itemId);
    } catch (error) {
        console.warn(`Could not set user asset: ${type + ' ' + itemId}, ${error}`);
    }
}

export async function unsetProfileAsset(type: ProfileAssetType) {
    try {
        await arise.unsetUserCurrentAsset(type);
    } catch (error) {
        console.warn(`Could not unset user asset type: ${type}, ${error}`);
    }
}

export function profileHasOnboarded(profile: Profile) {
    return profile && profile?.background && profile?.character && profile?.faction;
}
