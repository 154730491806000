import React, { useEffect, useRef, useState, ImgHTMLAttributes } from 'react';

interface ImageWithLoadingProps extends ImgHTMLAttributes<HTMLImageElement> {
    onLoad: () => void;
}

const ImageWithLoading = ({ onLoad, ...imgProps }: ImageWithLoadingProps) => {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (imgRef.current?.complete) {
            // Call onLoad if the image has already loaded (from cache)
            onLoad();
        }
    }, [onLoad]);

    const handleError = () => {
        setOpacity(0); // Set opacity to 0 if the image fails to load
        onLoad();
    };

    return <img ref={imgRef} onLoad={onLoad} onError={handleError} style={{ opacity }} {...imgProps} />;
};

export default ImageWithLoading;
