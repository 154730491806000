@import '../../../style/globals.scss';

.GuestFeedInputOverlay {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 52px;
    padding: 10px 115px 10px 15px;
    background-color: var(--token-accent-theme-1-interface-mono-interface-background);
    color: var(--token-accent-theme-1-interface-mono-interface-white);
    font-size: $font-size-p;
    border-radius: 10px;

    span {
        margin-right: 5px;
    }

    .loginButton {
        position: absolute;
        right: 10px;
        width: 100px;
        height: 40px;
        padding: 12px;
    }
}
