@import '../../../style/globals.scss';

$transition-duration-parallax: 2500ms;
$transition-timing-parallax: $animation-parallax;
$transition-duration-hover: 500ms;
$transition-timing-hover: $animation-bounce;

.SceneSelectorTile {
    position: relative;
    width: 100%;

    &__anchor {
        cursor: pointer;
        display: block;
        position: relative;
        width: 50%;
        transition: width $transition-duration-parallax $animation-parallax;
        height: 230px;
        margin-bottom: 45px;
        margin-left: auto;
        margin-right: auto;

        &:hover {
            .SceneSelectorTile__image {
                transform: scale(1.2);
            }

            .SceneSelectorTile__customBorder {
                stroke: var(--token-LEGACY-accent-theme-1-interface-interface-white);
            }

            .SceneSelectorTile__playButton {
                background-color: var(--token-LEGACY-accent-theme-1-interface-interface-active);
                svg {
                    path {
                        fill: var(--token-accent-theme-1-button-button-highlight);
                    }
                }
            }
        }
    }

    &--locked & {
        &__customBorder {
            stroke: var(--token-accent-theme-1-border-border-default);
        }

        &__anchor {
            &:hover {
                .SceneSelectorTile__customBorder {
                    stroke: var(--token-accent-theme-1-border-border-highlight);
                }
            }
        }
    }

    &--inCenter.SceneSelectorTile--locked & {
        &__anchor {
            cursor: auto;
        }
    }

    &--inCenter & {
        &__anchor {
            width: 100%;
        }

        &__textContainer {
            opacity: 1;
        }

        &__imageContainer {
            filter: brightness(1);
        }
    }

    &__customBorder {
        position: absolute;
        top: -1px;
        display: block;
        width: calc(100% + 3px);
        height: calc(100% + 3px);
        stroke: var(--token-LEGACY-accent-theme-1-interface-interface-grey);
        transition: stroke $transition-duration-hover $transition-timing-hover;
    }

    &__imageContainer {
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
        mask-size: 100% 100%;
        filter: brightness(0.5);
        transition: filter $transition-duration-parallax $transition-timing-parallax;
    }

    &__image {
        transition: transform $transition-duration-hover $transition-timing-hover;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__textContainer {
        margin-top: 20px;
        text-align: center;
        font-family: var(--font-primary);
        font-size: $font-size-h6;
        color: var(--token-accent-theme-1-text-text-default);
        opacity: 0;
        transition: opacity $transition-duration-parallax $transition-timing-parallax;
    }

    &__lockedIcon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
            fill: var(--token-LEGACY-accent-theme-1-brand-colour);
        }
    }

    &__lockedSubtext {
        color: var(--token-LEGACY-accent-theme-1-brand-colour);
    }

    &__playButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -37px;
        transition: background-color $transition-duration-hover $transition-timing-hover;
        svg {
            path {
                transition: fill $transition-duration-hover $transition-timing-hover;
            }
        }
    }

    @media (max-width: $bp-m) {
        &__anchor {
            height: 214px;
        }
        &__textContainer {
            font-size: $font-size-p;
        }
    }
    @media (max-width: $bp-xxs) {
        &__anchor {
            height: 190px;
        }
    }
}
