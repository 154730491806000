import { loadInstance } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useEffect } from 'react';

export const useTheme = () => {
    const theme = useAppSelector((state) => state.app.theme);
    return theme;
};

export function ThemeStylesProvider() {
    const theme = useTheme();

    return (
        <style>
            {`
                body {
                    color: ${theme.tokens.AccentTheme1TextTextDefault};

                    --font-primary: '${theme.fonts.primary}';
                    --font-secondary: '${theme.fonts.secondary}';
                    --font-tertiary: '${theme.fonts.tertiary}';
                    --font-quaternary: '${theme.fonts.quaternary}';
                    
                    --token-arise-theme-1-rarity-rarity-common-light: ${theme.tokens.AriseTheme1RarityRarityCommonLight};
                    --token-arise-theme-1-rarity-rarity-common-mid: ${theme.tokens.AriseTheme1RarityRarityCommonMid};
                    --token-arise-theme-1-rarity-rarity-common-dark: ${theme.tokens.AriseTheme1RarityRarityCommonDark};
                    --token-arise-theme-1-rarity-rarity-uncommon-light: ${theme.tokens.AriseTheme1RarityRarityUncommonLight};
                    --token-arise-theme-1-rarity-rarity-uncommon-mid: ${theme.tokens.AriseTheme1RarityRarityUncommonMid};
                    --token-arise-theme-1-rarity-rarity-uncommon-dark: ${theme.tokens.AriseTheme1RarityRarityUncommonDark};
                    --token-arise-theme-1-rarity-rarity-rare-light: ${theme.tokens.AriseTheme1RarityRarityRareLight};
                    --token-arise-theme-1-rarity-rarity-rare-mid: ${theme.tokens.AriseTheme1RarityRarityRareMid};
                    --token-arise-theme-1-rarity-rarity-rare-dark: ${theme.tokens.AriseTheme1RarityRarityRareDark};
                    --token-arise-theme-1-rarity-rarity-epic-light: ${theme.tokens.AriseTheme1RarityRarityEpicLight};
                    --token-arise-theme-1-rarity-rarity-epic-mid: ${theme.tokens.AriseTheme1RarityRarityEpicMid};
                    --token-arise-theme-1-rarity-rarity-epic-dark: ${theme.tokens.AriseTheme1RarityRarityEpicDark};
                    --token-arise-theme-1-rarity-rarity-legendary-light: ${theme.tokens.AriseTheme1RarityRarityLegendaryLight};
                    --token-arise-theme-1-rarity-rarity-legendary-mid: ${theme.tokens.AriseTheme1RarityRarityLegendaryMid};
                    --token-arise-theme-1-rarity-rarity-legendary-dark: ${theme.tokens.AriseTheme1RarityRarityLegendaryDark};
                    --token-arise-theme-1-state-state-error-light: ${theme.tokens.AriseTheme1StateStateErrorLight};
                    --token-arise-theme-1-state-state-error-mid: ${theme.tokens.AriseTheme1StateStateErrorMid};
                    --token-arise-theme-1-state-state-error-dark: ${theme.tokens.AriseTheme1StateStateErrorDark};
                    --token-arise-theme-1-state-state-success-light: ${theme.tokens.AriseTheme1StateStateSuccessLight};
                    --token-arise-theme-1-state-state-success-mid: ${theme.tokens.AriseTheme1StateStateSuccessMid};
                    --token-arise-theme-1-state-state-success-dark: ${theme.tokens.AriseTheme1StateStateSuccessDark};
                    --token-arise-theme-1-state-state-default-light: ${theme.tokens.AriseTheme1StateStateDefaultLight};
                    --token-arise-theme-1-state-state-default-mid: ${theme.tokens.AriseTheme1StateStateDefaultMid};
                    --token-arise-theme-1-state-state-default-dark: ${theme.tokens.AriseTheme1StateStateDefaultDark};
                    --token-accent-theme-1-interface-mono-interface-white: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoWhite};
                    --token-accent-theme-1-interface-mono-interface-active: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoActive};
                    --token-accent-theme-1-interface-mono-interface-foreground: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoForeground};
                    --token-accent-theme-1-interface-mono-interface-midground: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoMidground};
                    --token-accent-theme-1-interface-mono-interface-background: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoBackground};
                    --token-accent-theme-1-interface-mono-interface-black: ${theme.tokens.AccentTheme1InterfaceMonoInterfaceMonoBlack};
                    --token-accent-theme-1-interface-brand-interface-active: ${theme.tokens.AccentTheme1InterfaceBrandInterfaceActive};
                    --token-accent-theme-1-interface-brand-interface-foreground: ${theme.tokens.AccentTheme1InterfaceBrandInterfaceForeground};
                    --token-accent-theme-1-interface-brand-interface-midground: ${theme.tokens.AccentTheme1InterfaceBrandInterfaceMidground};
                    --token-accent-theme-1-interface-brand-interface-background: ${theme.tokens.AccentTheme1InterfaceBrandInterfaceBackground};
                    --token-accent-theme-1-border-border-default: ${theme.tokens.AccentTheme1BorderBorderDefault};
                    --token-accent-theme-1-border-border-highlight: ${theme.tokens.AccentTheme1BorderBorderHighlight};
                    --token-accent-theme-1-border-border-inactive: ${theme.tokens.AccentTheme1BorderBorderInactive};
                    --token-accent-theme-1-border-border-active: ${theme.tokens.AccentTheme1BorderBorderActive};
                    --token-accent-theme-1-brand-palette-brand-accent-1: ${theme.tokens.AccentTheme1BrandPaletteBrandAccent1};
                    --token-accent-theme-1-brand-palette-brand-accent-2: ${theme.tokens.AccentTheme1BrandPaletteBrandAccent2};
                    --token-accent-theme-1-brand-palette-brand-contrast-1: ${theme.tokens.AccentTheme1BrandPaletteBrandContrast1};
                    --token-accent-theme-1-brand-palette-brand-contrast-2: ${theme.tokens.AccentTheme1BrandPaletteBrandContrast2};
                    --token-accent-theme-1-brand-palette-brand-scale-1: ${theme.tokens.AccentTheme1BrandPaletteBrandScale1};
                    --token-accent-theme-1-brand-palette-brand-scale-2: ${theme.tokens.AccentTheme1BrandPaletteBrandScale2};
                    --token-accent-theme-1-brand-palette-brand-scale-3: ${theme.tokens.AccentTheme1BrandPaletteBrandScale3};
                    --token-accent-theme-1-brand-palette-brand-colour: ${theme.tokens.AccentTheme1BrandPaletteBrandColour};
                    --token-accent-theme-1-brand-palette-brand-colour-contrast: ${theme.tokens.AccentTheme1BrandPaletteBrandColourContrast};
                    --token-accent-theme-1-button-button-default: ${theme.tokens.AccentTheme1ButtonButtonDefault};
                    --token-accent-theme-1-button-button-highlight: ${theme.tokens.AccentTheme1ButtonButtonHighlight};
                    --token-accent-theme-1-button-button-active: ${theme.tokens.AccentTheme1ButtonButtonActive};
                    --token-accent-theme-1-button-button-inactive: ${theme.tokens.AccentTheme1ButtonButtonInactive};
                    --token-accent-theme-1-button-button-secondary-default: ${theme.tokens.AccentTheme1ButtonButtonSecondaryDefault};
                    --token-accent-theme-1-button-button-secondary-highlight: ${theme.tokens.AccentTheme1ButtonButtonSecondaryHighlight};
                    --token-accent-theme-1-button-button-secondary-active: ${theme.tokens.AccentTheme1ButtonButtonSecondaryActive};
                    --token-accent-theme-1-button-button-secondary-inactive: ${theme.tokens.AccentTheme1ButtonButtonSecondaryInactive};
                    --token-accent-theme-1-text-text-default: ${theme.tokens.AccentTheme1TextTextDefault};
                    --token-accent-theme-1-text-text-inactive: ${theme.tokens.AccentTheme1TextTextInactive};
                    --token-accent-theme-1-text-text-brand-2: ${theme.tokens.AccentTheme1TextTextBrand2};
                    --token-accent-theme-1-text-text-brand-1: ${theme.tokens.AccentTheme1TextTextBrand1};
                    --token-accent-theme-1-text-text-contrast-default: ${theme.tokens.AccentTheme1TextTextContrastDefault};
                    --token-accent-theme-1-text-text-link: ${theme.tokens.AccentTheme1TextTextLink};
                    --token-LEGACY-accent-theme-1-button-button-secondary: ${theme.tokens.LEGACYAccentTheme1ButtonButtonSecondary};
                    --token-LEGACY-accent-theme-1-text-text-active: ${theme.tokens.LEGACYAccentTheme1TextTextActive};
                    --token-LEGACY-accent-theme-1-text-text-secondary: ${theme.tokens.LEGACYAccentTheme1TextTextSecondary};
                    --token-LEGACY-accent-theme-1-interface-interface-active: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceActive};
                    --token-LEGACY-accent-theme-1-interface-interface-foreground: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceForeground};
                    --token-LEGACY-accent-theme-1-interface-interface-midground: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceMidground};
                    --token-LEGACY-accent-theme-1-interface-interface-background: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceBackground};
                    --token-LEGACY-accent-theme-1-interface-interface-white: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceWhite};
                    --token-LEGACY-accent-theme-1-interface-interface-grey: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceGrey};
                    --token-LEGACY-accent-theme-1-interface-interface-black: ${theme.tokens.LEGACYAccentTheme1InterfaceInterfaceBlack};
                    --token-LEGACY-accent-theme-1-brand-colour: ${theme.tokens.LEGACYAccentTheme1BrandColour};
                    --token-LEGACY-accent-theme-1-brand-colour-contrast: ${theme.tokens.LEGACYAccentTheme1BrandColourContrast};
                }
            `}
        </style>
    );
}
