import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as arise from '@/arise/api';
import { Profile, StorageProfile } from '@/models/profile';
import { RootState } from '@/state/store';
import { processProfileData } from '@/utils/profile';

interface ProfileState {
    currentUserProfile?: Profile;
    currentUserProfileCheckInProgress: boolean;
    currentUserProfileCheckComplete: boolean;

    profileByUserId: Record<string, StorageProfile>;
    isFetchingProfileById: Record<string, boolean>;
}

const initialState: ProfileState = {
    currentUserProfileCheckInProgress: false,
    currentUserProfileCheckComplete: false,

    profileByUserId: {},
    isFetchingProfileById: {},
};

export const initialProfileState = initialState;

export const addProfileTitle = createAsyncThunk<boolean, string>('profile/addProfileTitle', async (id: string) => {
    const result = await arise.addProfileTitle(id);
    return result.success;
});

export const setProfileTitle = createAsyncThunk<boolean, string>('profile/setProfileTitle', async (id: string) => {
    const result = await arise.setProfileTitle(id);
    return result.success;
});

export const setProfileFaction = createAsyncThunk<boolean, string>('profile/setProfileFaction', async (id: string) => {
    const result = await arise.setProfileFaction(id);
    return result.success;
});

export const loadCurrentUserProfile = createAsyncThunk<Profile, [userId: string]>('profile/load', async (userId) => {
    const profiles = await arise.getProfiles(userId);
    return profiles[0] ?? undefined;
});

export const loadUserProfiles = createAsyncThunk<Profile[] | null, string[]>(
    'profile/loadUserProfiles',
    async (userIds, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const ttl = Date.now() + 1000 * 60;
        const cleanedUserIds = userIds.filter((id) => {
            return (
                !state.profile.isFetchingProfileById[id] &&
                (!state.profile.profileByUserId[id] ||
                    (state.profile.profileByUserId[id] && state.profile.profileByUserId[id].fetchedAt > ttl))
            );
        });

        if (cleanedUserIds.length === 0) {
            return null;
        }

        thunkApi.dispatch(profile.actions.markProfilesAsFetching(cleanedUserIds));

        return await arise.getProfiles(cleanedUserIds);
    },
);

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        markProfilesAsFetching: (state, action) => {
            action.payload.forEach((id) => {
                state.isFetchingProfileById[id] = true;
            });
        },
        updateProfile: (state, action: PayloadAction<Profile>) => {
            const data = action.payload as Profile;

            const profile = processProfileData(data);

            const now = Date.now();
            profile.fetchedAt = now;

            // Not currenlty using the profile cache so commenting this out to avoid using unnecessary memory
            // state.profileByUserId[profile.userID] = profile;

            if (state.currentUserProfile?.id === profile.id) {
                state.currentUserProfile = profile;
            }
        },
        resetProfileState: (state) => {
            const newState = {
                ...initialState,
                profileByUserId: state.profileByUserId,
                isFetchingProfileById: state.isFetchingProfileById,
            };
            Object.assign(state, newState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadCurrentUserProfile.pending, (state) => {
            state.currentUserProfileCheckInProgress = true;
            state.currentUserProfileCheckComplete = false;
        });

        builder.addCase(loadCurrentUserProfile.fulfilled, (state, action) => {
            state.currentUserProfile = action.payload;
            state.currentUserProfileCheckInProgress = false;
            state.currentUserProfileCheckComplete = true;
        });

        builder.addCase(loadCurrentUserProfile.rejected, (state, action) => {
            console.error('Failed to load current user profile', action.error);
            state.currentUserProfileCheckInProgress = false;
            state.currentUserProfileCheckComplete = true;
        });

        builder.addCase(loadUserProfiles.fulfilled, (state, action) => {
            action.meta.arg.forEach((id) => {
                state.isFetchingProfileById[id] = false;
            });

            const now = Date.now();

            action.payload?.forEach((profile) => {
                const p = profile as StorageProfile;
                p.fetchedAt = now;
                state.profileByUserId[p.userID] = p;
            });
        });

        builder.addCase(loadUserProfiles.rejected, (state, action) => {
            console.error(action.error);
            action.meta.arg.forEach((id) => {
                state.isFetchingProfileById[id] = false;
            });
        });
    },
});

export const { updateProfile, resetProfileState } = profile.actions;
export const { actions: profileActions, reducer: profileReducer } = profile;

export default profileReducer;
