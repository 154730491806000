import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/state/store';
import { toggleSidebarOpen } from '@/state/features/app';
import { toggleMessageReaction } from '@/state/features/feed';
import { useAudio } from '@/hooks/audio';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { sendEvent } from '@/utils/analytics';
import { ChatMessageItem, MessageReactionSum, Reaction } from '@/models/feed';
import AvatarLayered from '@/components/AvatarLayered';

import * as styles from './styles.module.scss';

interface QuickReactProps {
    message: ChatMessageItem;
}

export default function QuickReact({ message }: QuickReactProps) {
    const locationId = useAppSelector((state) => state.app.location.id);
    const availableReactions = useAppSelector((state) => state.feed.availableReactions);
    const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen);

    const { audio } = useAudio();
    const dispatch = useAppDispatch();

    const openChat = () => {
        audio?.play(isSidebarOpen ? 'gust-close' : 'gust-open');
        dispatch(toggleSidebarOpen());
        sendEvent(isSidebarOpen ? 'chat_close' : 'chat_open');
    };

    function getHighestCountReaction(reactions: MessageReactionSum[]): Reaction | null {
        if (!reactions || reactions?.length === 0) return null;
        const highestReaction = reactions.reduce(
            (max, current) => (current.count > max.count ? current : max),
            reactions[0],
        );
        return availableReactions[highestReaction?.type] ?? null;
    }

    const reaction = getHighestCountReaction(message?.data?.reactions);

    function handleReactionSubmit() {
        if (!message || !reaction) return;
        dispatch(toggleMessageReaction([message.id, reaction.type, locationId]));
        sendEvent('toggle_reaction_quick_react', { reaction });
    }

    const shouldShowReactionButton = availableReactions && reaction;

    return (
        <div className={styles.QuickReact} aria-label={isSidebarOpen ? 'Close chat' : 'Open chat'}>
            <button className={styles.message} onClick={openChat}>
                <AvatarLayered
                    classname={styles.avatar}
                    backgroundImagePath={message?.data?.profile?.background?.thumbnailPath}
                    characterImagePath={message?.data?.profile?.character?.thumbnailPath}
                    frameImagePath={message?.data?.profile?.avatarFrame?.imagePath}
                />
                <div className={styles.text}>{message?.data?.message ?? ''}</div>
            </button>
            {shouldShowReactionButton && (
                <FloatingReactions reaction={reaction} handleReactionSubmit={handleReactionSubmit} />
            )}
        </div>
    );
}

function FloatingReactions({ reaction, handleReactionSubmit }) {
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const isGuest = useCurrentUserIsGuest();
    const notAllowedToReact = currentUserProfile?.isBanned || isGuest;
    const ReactionImage = () => <img src={reaction.imageUrl} alt=" " />;

    const [reactions, setReactions] = useState([
        <ReactionImage key={0} />,
        <ReactionImage key={1} />,
        <ReactionImage key={2} />,
    ]);

    const [isDisabled, setIsDisabled] = useState(false);

    const onClick = () => {
        handleReactionSubmit();
        setReactions([...reactions, <ReactionImage key={reactions.length} />]);
        setIsDisabled(true); // Disable the button after click
    };

    return (
        <button
            className={styles.react}
            onClick={onClick}
            aria-label={`Send ${reaction.title} reaction`}
            disabled={notAllowedToReact || isDisabled}
        >
            <div className={styles.floatingReactions} aria-hidden={true}>
                {reactions}
            </div>
            <img className={styles.reactionButtonImg} src={reaction.imageUrl} alt={reaction.title} />
        </button>
    );
}
