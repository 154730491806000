import classNames from 'classnames';

import { Background, Character } from '@/models/profile';
import { transitionStates } from '@/utils/pageTransition';
import AvatarLayered from '@/components/AvatarLayered';

import * as styles from './styles.module.scss';

interface AvatarLayeredPickerProps {
    backgrounds: Background[];
    characters: Character[];
    selectedCharacterId?: string;
    selectedBackgroundId?: string;
    onBackgroundSelected?: (id: string) => void;
    onCharacterSelected?: (id: string) => void;
    onAvatarMouseEnter?: () => void;
    transitionState: transitionStates;
}

export default function AvatarLayeredPicker({
    backgrounds,
    characters,
    selectedCharacterId,
    selectedBackgroundId,
    onBackgroundSelected,
    onCharacterSelected,
    onAvatarMouseEnter,
    transitionState = transitionStates.visible,
}: AvatarLayeredPickerProps) {
    const renderAvatars = () => {
        if (!backgrounds?.length || !characters?.length) {
            console.warn('Not enough backgrounds or characters provided for onboarding');
            return 'No avatars available';
        }

        const NUMBER_OF_CHARACTERS = 2;
        const NUMBER_OF_BACKGROUNDS = 4;

        return characters.slice(0, NUMBER_OF_CHARACTERS).flatMap((character) =>
            backgrounds.slice(0, NUMBER_OF_BACKGROUNDS).map((background) => (
                <li
                    key={`${character.id}-${background.id}`}
                    className={classNames(styles.AvatarPickerAvatar, styles[transitionState], {
                        [styles['AvatarPickerAvatar--selected']]:
                            selectedCharacterId === character.id && selectedBackgroundId === background.id,
                    })}
                >
                    <AvatarLayered
                        classname={styles.AvatarPickerAvatar__Button}
                        title={`Avatar with ${character.name} and ${background.name}`}
                        onClick={() => {
                            onBackgroundSelected && onBackgroundSelected(background.id);
                            onCharacterSelected && onCharacterSelected(character.id);
                        }}
                        onMouseEnter={onAvatarMouseEnter && onAvatarMouseEnter}
                        characterImagePath={character.thumbnailPath}
                        backgroundImagePath={background.imagePath}
                        imageHeightDimension={200}
                    />
                </li>
            )),
        );
    };

    return <ul className={styles.AvatarPicker}>{renderAvatars()}</ul>;
}
