import classNames from 'classnames';

import { Link } from 'react-router-dom';
import * as styles from './styles.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
    className?: string;
    isSecondary?: boolean;
}
export default function Button({ children, onClick, style, className, isSecondary, ...props }: ButtonProps) {
    return (
        <button
            className={classNames(className, styles.Button, { [styles.secondary]: isSecondary })}
            style={style}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
}

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    children?: string;
    style?: React.CSSProperties;
    className?: string;
    href?: string;
    isSecondary?: boolean;
}
export function LinkButton({ children, style, className, href, isSecondary, ...props }: LinkButtonProps) {
    return (
        <Link
            className={classNames(className, styles.Button, { [styles.secondary]: isSecondary })}
            style={style}
            to={href}
            {...props}
        >
            {children}
        </Link>
    );
}
