@import '../../style/globals.scss';

$quickReactWidth: 450px;
$chatButtonWidth: 50px;
$gap: 15px;

.QuickChatDrawer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: $quickReactWidth + $chatButtonWidth + $gap;
    height: 65px;
    transition: transform 700ms $animation-overshoot;
    transform: translateX(100%);
    pointer-events: none;

    &.chatWidgetState {
        transform: translateX(calc(100% - ($gap + $chatButtonWidth + $gap)));
    }

    &.chatWidgetAndQuickReactState {
        transform: translateX(0);
    }

    &.hiddenState {
        transform: translateX(100%);
        transition: transform 200ms linear;
    }

    .chatWidget {
        position: absolute;
        top: 0;
        left: $gap;
        pointer-events: all;
    }

    .quickReact {
        position: absolute;
        top: 3px;
        left: $gap + $chatButtonWidth + $gap;
        width: calc(100% - ($gap + $chatButtonWidth + $gap + $gap));
        max-width: 450px;
        pointer-events: all;
    }
}
