import classNames from 'classnames';

import { ArisewareScore } from '@/models/misc';
import { secondsIntoTimeString } from '@/utils/time';

import * as styles from './styles.module.scss';

interface ArisewareScoresProps {
    arisewareScores: ArisewareScore[];
    classname?: string;
}

const getFormattedScoreString = (score: ArisewareScore) => {
    if (score.score) {
        return secondsIntoTimeString(score.score);
    }
    return score.isCompleted ? 'Complete' : 'Incomplete';
};

const ArisewareScores = ({ arisewareScores, classname }: ArisewareScoresProps) => {
    if (!arisewareScores?.length) return null;

    return (
        <div className={classNames(styles.ArisewareScores, classname)}>
            <ul>
                {arisewareScores?.map((score: ArisewareScore, index: number) => {
                    return (
                        <li key={index}>
                            <span>{score.displayName}:</span>
                            <span
                                className={classNames({
                                    [styles.success]: score.isCompleted,
                                    [styles.failure]: !score.isCompleted,
                                })}
                            >
                                {getFormattedScoreString(score)}
                            </span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ArisewareScores;
