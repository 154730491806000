import { useInitialAuthCheck } from '@/hooks/auth';
import { useAppSelector } from '@/state/store';
import WorldIntro from '@/components/WorldIntro';

export default function SceneSelectorLayout({ children }: { children: React.ReactNode }) {
    useInitialAuthCheck();
    const auth = useAppSelector((state) => state.auth);
    if (!auth.loggedIn) {
        return <></>;
    }
    return <WorldIntro>{children}</WorldIntro>;
}
