import classNames from 'classnames';

import Icon, { IconTypes } from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

import * as styles from './styles.module.scss';

export type IconButtonStyleTypes = 'round' | 'icon' | 'diamond';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    iconType: IconTypes;
    ariaLabel: string;
    buttonStyle: IconButtonStyleTypes;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    tooltipContent?: string;
    tooltipIsVisible?: boolean;
    hasDot?: boolean;
}

export default function IconButton({
    iconType,
    ariaLabel,
    buttonStyle = 'round',
    className,
    buttonRef,
    tooltipContent,
    tooltipIsVisible,
    hasDot,
    ...props
}: IconButtonProps) {
    return (
        <button
            className={classNames(styles.IconButton, className, {
                [styles['isRoundStyle']]: buttonStyle === 'round',
                [styles['isIconStyle']]: buttonStyle === 'icon',
                [styles['isDiamondStyle']]: buttonStyle === 'diamond',
                [styles['hasDot']]: hasDot,
            })}
            aria-label={ariaLabel}
            ref={buttonRef}
            {...props}
        >
            <Icon type={iconType} size={24} />
            {tooltipContent && <Tooltip isVisible={tooltipIsVisible}>{tooltipContent}</Tooltip>}
        </button>
    );
}
