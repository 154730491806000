@import '../../style/globals.scss';

.blur {
    &.visible {
        // Needed for safari
        -webkit-backdrop-filter: blur(10px);
    }
}

.modalDrawer {
    position: absolute;
    z-index: 5;
    top: calc(100% + 100px);
    left: 0px;
    transition: top 300ms ease-out, transform 300ms ease-out;
    width: 100%;
    pointer-events: none;

    &.visible {
        top: 50%;
        transform: translateY(-50%);
    }
}

$space-for-menu-buttons: 200px; // To allow for main menu and chat buttons to be visible around modal

.Modal {
    position: relative;
    width: 565px;
    max-width: 100%;
    height: 227px;
    max-height: 100vh;
    max-height: 100dvh; // Fix for mobile safari
    padding: 10px 2px;
    margin: 0 auto;
    background-size: 100% 100%;
    color: var(--token-accent-theme-1-text-text-default);
    text-align: center;
    pointer-events: all;

    .modalTitle {
        font-family: var(--font-primary);
        font-size: $font-size-h5;
    }

    &.noBackgroundImage {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
    }

    &.leaveSpaceAboveAndBelow {
        max-height: calc(100vh - $space-for-menu-buttons);
        max-height: calc(100dvh - $space-for-menu-buttons); // Fix for mobile safari

        .contentContainer {
            max-height: calc(100vh - ($space-for-menu-buttons + 20px));
            max-height: calc(100dvh - ($space-for-menu-buttons + 20px)); // Fix for mobile safari
        }
    }

    .closeButton {
        position: absolute;
        z-index: 1;
        top: -5px;
        right: -5px;
    }

    .contentContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        max-height: calc(100vh - 20px);
        max-height: calc(100dvh - 20px); // Fix for mobile safari
        padding: 20px;
    }

    &.canScroll {
        .contentContainer {
            @include scrollbar-styles;
            display: block;
            overflow: auto;
        }
    }
}
