import { tokenExchange } from '@/arise/api';
import { mapPath } from '@/theme/vars';
import * as insiders from '@/third-party/insiders';
import { sendEvent } from '@/utils/analytics';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function InsidersLogin() {
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            // get query params
            const urlParams = new URLSearchParams(window.location.search);
            const insidersJWT = urlParams.get('jwt');

            if (!insidersJWT) {
                console.error('No jwt found in query params');
                navigate('/');
                sendEvent('login_return_from_insiders_error', { message: 'No jwt found in query params' });
                return;
            }

            // remove jwt token from query params in url
            window.history.replaceState({}, document.title, window.location.pathname);

            insiders.setToken(insidersJWT);

            try {
                // TODO: verify we can get user info from insiders once CORS is fixed
                // const insidersMe = await insiders.me();

                sendEvent('login_return_from_insiders_success');
                const res = await tokenExchange(insidersJWT, 'insiders');
                if (res.me.profile) {
                    navigate(mapPath);
                } else {
                    navigate('/edit-profile');
                }
            } catch (error) {
                // TODO: handle error better, message to user?
                console.error(error);
                sendEvent('login_return_from_insiders_error', { message: 'Token exchange or other error' });

                setTimeout(() => {
                    // redirect to home page after 2 seconds (gives us a chance to see error in console)
                    navigate('/');
                }, 2000);
            }
        })();
    }, []);

    return <></>;
}
