@import '../../style/globals.scss';

.DisplayNameWithBadges {
    .displayName {
        display: inline-block;
        margin-right: 6px;
    }

    .badgeContainer {
        display: inline-block;

        .titleBadge, .systemBadge {
            margin-right: 6px;
        }
    }
}
