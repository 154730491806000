@import '../../../style/globals.scss';

.ArisewareScores {
    height: 88px;
    border-bottom: $line-style;
    font-family: var(--font-primary);
    font-size: $font-size-p;

    .success {
        color: var(--token-arise-theme-1-state-state-success-light);
    }
    .failure {
        color: var(--token-arise-theme-1-state-state-error-light);
    }

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    li {
        display: flex;
        flex: 1 1 33.33%;
        flex-direction: column;
        text-align: center;

        span {
            display: block;
        }
    }
}
