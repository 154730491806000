@use 'sass:math';
@import '../../style/globals.scss';

$transition: left 2500ms $animation-parallax;
$transition-tiles: left 1000ms ease-in-out; // Should match SWIPE_DELAY in ts

$tileSizeLarge: 470px;
$peekAmountLarge: 200px;

$tileSizeMedium: 286px;
$peekAmountMedium: 110px;

$tileSizeSmall: 200px;
$peekAmountSmall: 80px;

$maxNumberOfScenesSupported: 10;

.SceneSelector {
    // Aspect ratio of each scene
    $aspectRatioWidth: 25;
    $aspectRatioHeight: 7;
    height: 100%;
    width: 100%;
    min-width: 320px;
    position: absolute;
    background-color: var(
        --token-LEGACY-accent-theme-1-interface-interface-black
    ); // Should match bottom of parallax images

    &__parallaxContainer {
        position: relative;
        overflow: hidden;
        user-select: none;
        user-drag: none;
        pointer-events: none;
        margin-bottom: 50%;

        // Calc prevents a Sass warning
        @media (max-aspect-ratio: calc($aspectRatioWidth) / calc($aspectRatioHeight * 2)) {
            height: 50%;
        }
    }

    &__parallax {
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: calc($aspectRatioWidth) / calc($aspectRatioHeight); // Calc prevents a Sass warning
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .StoryText {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        font-style: italic;
        text-transform: uppercase;
        text-align-last: right;
        transition: $transition, top $page-transition-time $animation-parallax,
            opacity $page-transition-time $animation-parallax;

        &__Item {
            position: relative;
            display: inline-flex;
            justify-content: center;
            overflow: hidden;
            height: 100%;

            p {
                position: absolute;
                top: 15px;
                display: block;
                max-width: 100%;
                max-width: min(calc(100% - 40px), 500px);
                padding: 5px 20px;
                margin: 0 20px;
                background-color: var(--token-LEGACY-accent-theme-1-text-text-active);
                color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
                font-family: var(--font-primary);
                font-size: $font-size-h6;
                line-height: 23px;
                // Comic style edge
                background-clip: padding-box;
                border-color: transparent;
                border-width: 6px 10px 6px 10px;
                border-style: solid;
                border-image-slice: 15;
                border-image-width: repeat;
                border-image-outset: repeat;
                background-size: 200% 200%;
                background-position: center;

                @media (max-width: $bp-xs) {
                    font-size: $font-size-p;
                    line-height: 16px;
                    padding: 7px 12px;
                }

                @media (max-width: $bp-s) {
                    margin-left: calc($header-height + 5px);
                }
            }
        }
    }

    $parallaxScaleXAmount: 1.01; // Must be slightly zoomed in to allow for sway animation

    .parallaxLayer {
        position: absolute;
        left: 0;
        height: 100%;
        transition: $transition, top $page-transition-time $animation-parallax;
        max-width: initial;
        transform: scaleX($parallaxScaleXAmount);
    }

    .parallaxLayerFrontCover {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        transition: top $page-transition-time $animation-parallax;
        background-color: var(
            --token-LEGACY-accent-theme-1-interface-interface-black
        ); // Should match bottom of parallax images
    }

    @mixin sway-animation-mixin($name, $amount) {
        @keyframes sway-animation-#{$name} {
            0% {
                transform: translateX(0) scaleX($parallaxScaleXAmount);
            }
            100% {
                transform: translateX(#{percentage($amount * 0.001)}) scaleX($parallaxScaleXAmount);
            }
        }
        animation: sway-animation-#{$name} 4s ease-in-out alternate infinite;
    }

    .parallaxLayer {
        &-bg {
            @include sway-animation-mixin(bg, 3);
        }
        &-mg {
            @include sway-animation-mixin(mg, 5);
        }
        &-fg {
            @include sway-animation-mixin(fg, 10);
        }
    }

    @function getCarouselLeftValue($widthOfItem, $peekAmount, $sceneNumber) {
        @return calc(((50% - ($widthOfItem / 2 + $peekAmount) + $widthOfItem) * $sceneNumber) * -1);
    }

    @for $i from 0 through $maxNumberOfScenesSupported {
        &.scene-#{$i} {
            ul {
                left: getCarouselLeftValue($tileSizeLarge, $peekAmountLarge, #{$i});
                @media (max-width: $bp-m) {
                    left: getCarouselLeftValue($tileSizeMedium, $peekAmountMedium, #{$i});
                }
                @media (max-width: $bp-xxs) {
                    left: getCarouselLeftValue($tileSizeSmall, $peekAmountSmall, #{$i});
                }
            }
        }
    }

    &__scenes {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 450px;
        display: flex;
        align-items: center;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%);
        transition: opacity $page-transition-time linear;

        @media (max-width: $bp-xs) {
            height: auto;
            margin-bottom: 10px;
        }
    }

    ul {
        width: 100%;
        position: relative;
        white-space: nowrap;
        transition: $transition-tiles;
    }

    &__sceneContainer {
        position: relative;
        display: inline-block;
        white-space: normal;
        vertical-align: top;

        width: $tileSizeLarge;
        margin-left: calc(50% - (($tileSizeLarge / 2) + $peekAmountLarge));
        &:first-child {
            margin-left: calc(50% - ($tileSizeLarge / 2));
        }
        &:last-child {
            margin-right: calc(50% - ($tileSizeLarge / 2));
        }

        @media (max-width: $bp-m) {
            width: $tileSizeMedium;
            margin-left: calc(50% - (($tileSizeMedium / 2) + $peekAmountMedium));
            &:first-child {
                margin-left: calc(50% - ($tileSizeMedium / 2));
            }
            &:last-child {
                margin-right: calc(50% - ($tileSizeMedium / 2));
            }
        }

        @media (max-width: $bp-xxs) {
            width: $tileSizeSmall;
            margin-left: calc(50% - (($tileSizeSmall / 2) + $peekAmountSmall));
            &:first-child {
                margin-left: calc(50% - ($tileSizeSmall / 2));
            }
            &:last-child {
                margin-right: calc(50% - ($tileSizeSmall / 2));
            }
        }
    }

    // Page transitions
    &.page-transition {
        &-preVisible {
            .parallaxLayer {
                opacity: 0;
            }
            .StoryText {
                opacity: 0;
                top: -200px;
            }
        }

        &-preVisible,
        &-postVisible {
            .parallaxLayerFrontCover {
                top: 0%;
            }
            .parallaxLayer {
                &-bg {
                    top: -10%;
                }
                &-mg {
                    top: -20%;
                }
                &-fg {
                    top: -50%;
                }
            }
        }

        &-visible {
            .parallaxLayerFrontCover {
                top: 100%;
            }
            .parallaxLayer {
                opacity: 1;
                top: 0%;
            }
        }

        &-postVisible {
            .SceneSelector__scenes {
                opacity: 0;
            }
            .StoryText {
                opacity: 0;
                top: -200px;
            }
        }
    }
}
