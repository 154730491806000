import classNames from 'classnames';

import { AssetPack } from '@/models/shop';
import { useTheme } from '@/theme';
import { getPriceString } from '@/utils/currency';
import ProfileAssetsContainer from '@/components/ProfileDrawer/ProfileAssetsContainer';
import Button from '@/components/Forms/Button';
import CardDescription from '@/components/CardDescription';
import Icon from '@/components/Icon';

import * as styles from './styles.module.scss';

interface BundleItemProps {
    classname?: string;
    onSelected: () => void;
    pack: AssetPack;
}

export const BundleItem = ({ classname, onSelected, pack }: BundleItemProps) => {
    const theme = useTheme();
    if (!pack) return;
    const { background, character, avatarFrame } = pack.assets;
    const formattedPrice = getPriceString(pack.currency, pack.price);
    const canBePurchased = formattedPrice && !pack.purchased;

    return (
        <div
            className={classNames(styles.BundleItem, classname, { [styles.longTitle]: pack.name?.length > 20 })}
            style={{ backgroundImage: `url("${theme.customJSON?.cardBackgroundImageUrl}")` }}
        >
            {/* <CardDescription
                classname={styles.label}
                title="Limited Edition"
                subtitle="Leaving Soon"
                maskImage={theme.customJSON?.cardTextBackgroundImageUrl}
                keepFontSize
            /> */}
            <ProfileAssetsContainer
                background={background}
                character={character}
                avatarFrame={avatarFrame}
                avatarClassname={styles.avatar}
            />
            <div className={styles.interactionContainer}>
                <CardDescription
                    classname={styles.cardDescription}
                    title={pack.name}
                    subtitle={pack.rarity}
                    rarity={pack.rarity}
                    onClick={canBePurchased ? onSelected : null}
                    maskImage={theme.customJSON?.cardTextBackgroundImageUrl}
                    iconType="info"
                    keepFontSize
                />
                <Button onClick={onSelected} disabled={!canBePurchased}>
                    {formattedPrice ? 'Buy for ' + formattedPrice : 'Unavailable'}
                </Button>
            </div>
            {pack.purchased && (
                <div className={classNames(styles.purchasedOverlay)}>
                    <div>
                        <Icon type="priority" />
                        <p>Already Purchased</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BundleItem;
