@import '../../style/globals.scss';

.QuickReact {
    position: relative;
    display: flex;
    height: 45px;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.6));
    background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;

    .message {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: calc(100% - 50px);
        height: 100%;
        padding-left: 15px;

        .text {
            overflow: hidden;
            width: 100%;
            font-size: $font-size-p;
            font-weight: 500;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .avatar {
            width: 35px;
            height: 35px;
            border-radius: 100%;
            flex: none;
            margin-right: 20px;
        }
    }

    $reaction-size: 25px;

    .react {
        position: relative;
        flex: none;
        padding: 10px 15px 10px 10px;
        border-radius: 15px;

        .reactionButtonImg {
            width: $reaction-size;
            height: $reaction-size;

            &:hover {
                transform: scale(1.1);
            }
        }

        &:disabled .reactionButtonImg {
            &:hover {
                transform: none;
            }
        }
    }

    .floatingReactions {
        pointer-events: none;
        position: absolute;
        top: 10px;
        left: 10px;
        width: $reaction-size;
        height: $reaction-size;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            animation-name: yAnimation, xAnimation;
            animation-duration: 2000ms;
            animation-timing-function: linear, ease-in-out;

            &:nth-child(2) {
                animation-delay: 1s;
                animation-name: yAnimation, xAnimation-alt;
            }

            &:nth-child(3) {
                animation-delay: 1.5s;
            }

            @keyframes yAnimation {
                0% {
                    opacity: 1;
                    bottom: 0;
                }
                50% {
                    opacity: 0.8;
                }
                100% {
                    opacity: 0;
                    bottom: 200px;
                }
            }

            @keyframes xAnimation {
                0% {
                    left: 0;
                    transform: scale(1);
                }
                35% {
                    left: -35px;
                    transform: scale(1.2);
                }
                75% {
                    left: 30px;
                }
                100% {
                    left: 0;
                    transform: scale(0.8);
                }
            }
            // Same but in other direction
            @keyframes xAnimation-alt {
                0% {
                    left: 0;
                    transform: scale(1);
                }
                35% {
                    left: 35px;
                    transform: scale(1.2);
                }
                75% {
                    left: -30px;
                }
                100% {
                    left: 0;
                    transform: scale(0.8);
                }
            }
        }
    }
}
