import classNames from 'classnames';
import * as styles from './styles.module.scss';

interface TooltipProps {
    children: React.ReactNode;
    isVisible: boolean;
    classname?: string;
    autoPosition?: boolean;
}

export default function Tooltip({ children, isVisible, classname, autoPosition = true }: TooltipProps) {
    return (
        <div
            className={classNames(styles.Tooltip, classname, {
                [styles.isVisible]: isVisible,
                [styles.autoPosition]: autoPosition,
            })}
        >
            {children}
        </div>
    );
}
