@import '../../../style/globals.scss';

.FailedOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: $font-size-h3;
    z-index: 20;
    translate: 0 -100%;
    height: 0;
    overflow: hidden;
    transition: translate 1s ease-in-out, height 0s linear;
    transition-delay: 0s, 0.3s;
    pointer-events: none;

    &.visible {
        transition-delay: 0s, 0s;
        height: 640px;
        translate: 0 0;

        @media (max-height: 800px) {
            height: 550px;
        }
    }

    @media screen and (max-width: $bp-xxs) {
        font-size: $font-size-h4;
    }
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
    opacity: 70%;
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: 90%;
}

.text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: $header-height + 30px;
    text-align: center;
    font-family: var(--font-primary);
}
