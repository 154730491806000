import { useState } from 'react';
import classNames from 'classnames';

import ProfileBackground from '@/components/ProfileDrawer/ProfileBackground';
import ProfileCharacter from '@/components/ProfileDrawer/ProfileCharacter';
import AvatarLayered from '@/components/AvatarLayered';
import LoadingScreen from '@/components/LoadingScreen';
import { AvatarFrame, Background, Character } from '@/models/profile';

import * as styles from './styles.module.scss';

interface ProfileAssetsContainerProps {
    classname?: string;
    background: Background;
    backgroundClassname?: string;
    character: Character;
    characterClassname?: string;
    avatarFrame: AvatarFrame;
    avatarClassname?: string;
}

export const ProfileAssetsContainer = ({
    classname,
    background,
    backgroundClassname,
    character,
    characterClassname,
    avatarFrame,
    avatarClassname,
}: ProfileAssetsContainerProps) => {
    const [characterIsLoaded, setCharacterIsLoaded] = useState(false);
    const [backgroundIsLoaded, setBackgroundIsLoaded] = useState(false);
    const isLoading = !(characterIsLoaded && backgroundIsLoaded);
    return (
        <div className={classNames(styles.ProfileAssetsContainer, classname)}>
            <ProfileBackground
                classname={classNames(styles.background, backgroundClassname)}
                onLoad={() => setBackgroundIsLoaded(true)}
                rivUrl={background?.rivFileURL}
                imagePath={background?.imagePath}
            />
            <ProfileCharacter
                classname={classNames(styles.character, characterClassname)}
                onLoad={() => setCharacterIsLoaded(true)}
                rivUrl={character?.rivFileURL}
                imagePath={character?.imagePath}
                hexColour={background?.colour}
            />
            <AvatarLayered
                classname={classNames(styles.avatar, avatarClassname)}
                backgroundImagePath={background?.thumbnailPath}
                characterImagePath={character?.thumbnailPath}
                frameImagePath={avatarFrame?.imagePath}
            />
            <LoadingScreen show={isLoading} classname={styles.loadingScreen} />
        </div>
    );
};

export default ProfileAssetsContainer;
