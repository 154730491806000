import { LinkButton } from '@/components/Forms/Button';
import { getInsidersLoginUrl } from '@/theme/vars';
import { alternativeLoginFormProps } from '../login/page';
import { sendEvent } from '@/utils/analytics';

export default function InsidersLoginForm({ onComplete }: alternativeLoginFormProps) {
    return (
        <>
            <LinkButton
                onClick={() => {
                    sendEvent('login_page_insiders_login_click');
                    onComplete();
                }}
                href={getInsidersLoginUrl()}
            >
                Log In
            </LinkButton>
        </>
    );
}
