import { useEffect, useState } from 'react';

import { useTheme } from '@/theme';
import { LS_HASSEENWORLDINTRO_KEY } from '@/globals';
import { transitionStates } from '@/utils/pageTransition';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { setHasWorldIntroPlayed } from '@/state/features/app';
import AnimatedFullscreenText from '@/components/AnimatedFullscreenText';

interface WorldIntroProps {
    children: React.ReactNode;
}

// Plays world intro, then fades to show children (or not, if already seen)
export default function WorldIntro({ children }: WorldIntroProps) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const hasWorldIntroPlayed = useAppSelector((state) => state.app.hasWorldIntroPlayed);
    const isPurchaseInProgress = useAppSelector((state) => state.app.isPurchaseInProgress);
    const [transitionState, setTransitionState] = useState(transitionStates.preVisible);
    const titles = theme.customJSON?.worldIntroductionText;

    useEffect(() => {
        // Only show intro to user once
        const hasSeenWorldIntro =
            hasWorldIntroPlayed || window.localStorage.getItem(LS_HASSEENWORLDINTRO_KEY) || !titles?.length;
        if (hasSeenWorldIntro) {
            dispatch(setHasWorldIntroPlayed(true));
        } else {
            setTransitionState(transitionStates.visible);
        }
    }, [theme]);

    useEffect(() => {
        if (isPurchaseInProgress) {
            // If doing a purchase, don't show world intro
            onComplete();
        }
    }, [isPurchaseInProgress]);

    const onComplete = () => {
        setTransitionState(transitionStates.postVisible);
        window.localStorage.setItem(LS_HASSEENWORLDINTRO_KEY, 'true');
        dispatch(setHasWorldIntroPlayed(true));
    };

    return (
        <>
            <AnimatedFullscreenText titles={titles} transitionState={transitionState} onComplete={onComplete} />
            {hasWorldIntroPlayed && children}
        </>
    );
}
