import classNames from 'classnames';
import { useEffect, useState } from 'react';

import * as styles from './styles.module.scss';
import { transitionStates } from '@/utils/pageTransition';

/*
    Full screen component which displays an array of titles like a slideshow
    - First title animates in word by word
    - Subsequent titles animate in more quickly
    - First word of each title is highlighted
*/
interface AnimatedFullscreenTextProps {
    titles: string[];
    transitionState: transitionStates;
    onComplete: () => void;
}

export default function AnimatedFullscreenText({ titles, transitionState, onComplete }: AnimatedFullscreenTextProps) {
    const [visibleTitleIndex, setVisibleTitleIndex] = useState(-1);

    const getWords = (title: string) => {
        const arrayOfWords = title.split(' ');
        const animateInDurationMs = 1000;
        const durationPerWord = animateInDurationMs / arrayOfWords.length;

        return arrayOfWords.map((word, index) => (
            <span
                key={index}
                style={{ animationDelay: `-${durationPerWord * (arrayOfWords.length - 1 - index)}ms` }} // Negative delay starts animation part way through
                className={classNames({
                    [styles.highlighted]: index === arrayOfWords.length - 1,
                })}
            >
                {word + (index < arrayOfWords.length - 1 ? ' ' : '')}
            </span>
        ));
    };

    useEffect(() => {
        // Transition between titles
        if (!titles || transitionState !== transitionStates.visible) return;
        const getAnimationDuration = (index: number) => {
            switch (index) {
                case -1:
                    return 0;
                case 0:
                    return 2000;
                default:
                    return 1000;
            }
        };

        let timeout;
        if (visibleTitleIndex < titles.length) {
            timeout = setTimeout(() => {
                setVisibleTitleIndex(visibleTitleIndex + 1);
            }, getAnimationDuration(visibleTitleIndex));
        } else {
            // No more titles to show
            onComplete();
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [visibleTitleIndex, titles, transitionState]);

    return (
        <div
            className={classNames(styles.AnimatedFullscreenText, styles[transitionState])}
            aria-hidden={transitionState !== transitionStates.visible}
            aria-live="assertive"
            aria-label={titles && titles.join('. ')}
        >
            {titles &&
                titles.map((title, index) => (
                    <div
                        key={index}
                        className={classNames(styles.title, {
                            [styles[transitionStates.preVisible]]: visibleTitleIndex < index,
                            [styles[transitionStates.visible]]: visibleTitleIndex === index,
                            [styles[transitionStates.postVisible]]:
                                visibleTitleIndex > index && index !== titles.length - 1,
                            [styles.longTitle]: title.length > 16,
                        })}
                    >
                        {getWords(title)}
                    </div>
                ))}
        </div>
    );
}
