import { useEffect } from 'react';
import classNames from 'classnames';

import Feed from '@/components/Feed';
import SidebarEvent from '@/components/Events/SidebarEvent';
import FailedOverlay from '@/components/Sidebar/FailedOverlay';
import IconButton from '@/components/IconButton';
import ProfileDrawer from '@/components/ProfileDrawer';
import ShopDrawer from '@/components/ShopDrawer';
import PinnedMessage from '@/components/Feed/PinnedMessage';
import { setIsShopOpen, setIsSidebarOpen } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { sendEvent } from '@/utils/analytics';
import { isMobileWidth } from '@/utils/mobile';

import * as styles from './styles.module.scss';

interface SidebarProps {
    locationTitle: string;
    locationName: string;
}

export default function Sidebar({ locationTitle, locationName }: SidebarProps) {
    const dispatch = useAppDispatch();
    const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen);
    const isProfileOpen = useAppSelector((state) => state.app.isProfileOpen);
    const isShopOpen = useAppSelector((state) => state.app.isShopOpen);
    const hasSeenShop = useAppSelector((state) => state.app.hasSeenShop);
    const isGuest = useCurrentUserIsGuest();
    const { audio } = useAudio();

    useEffect(() => {
        // Open the sidebar if the user is not on mobile
        dispatch(setIsSidebarOpen(!isMobileWidth()));
    }, []);

    const isInteractable = isSidebarOpen && !isProfileOpen && !isShopOpen;

    return (
        <div className={styles.Sidebar} style={{ backdropFilter: `blur(10px)` }}>
            <div className={styles.top} inert={isInteractable ? undefined : ''}>
                <IconButton
                    iconType="close"
                    ariaLabel="Close chat"
                    className={classNames(styles.closeButton)}
                    buttonStyle="round"
                    onClick={() => {
                        audio?.play('gust-close');
                        dispatch(setIsSidebarOpen(false));
                        sendEvent('chat_close');
                    }}
                />
                <IconButton
                    buttonStyle="round"
                    iconType="shop"
                    title="Open Shop"
                    ariaLabel="Open Shop"
                    onClick={() => {
                        dispatch(setIsShopOpen(true));
                    }}
                    className={styles.shopButton}
                    tooltipContent="New Shop"
                    tooltipIsVisible={!hasSeenShop && !isMobileWidth()}
                />
                <div className="text">
                    {locationTitle && (
                        <h2 className={styles.title}>
                            {locationTitle}
                            {locationName && ':'}
                        </h2>
                    )}
                    {locationName && <h1 className={styles.title}>{locationName}</h1>}
                </div>
                <SidebarEvent />
            </div>
            <div className={styles.bottom} inert={isInteractable ? undefined : ''}>
                {isGuest && <div className={styles.guestChatCover}>&nbsp;</div>}
                <div className={styles.feedContainer}>
                    <Feed />
                </div>
                <div className={styles.infobar}>&nbsp;</div>
                <PinnedMessage />
            </div>
            <ProfileDrawer />
            <ShopDrawer />
            <FailedOverlay />
        </div>
    );
}
