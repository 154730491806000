import classNames from 'classnames';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

import BackgroundImageWithLoading from '@/components/BackgroundImageWithLoading';
import { useTheme } from '@/theme';
import { pathToURL } from '@/utils/urls';
import { getColourFilterStyle } from '@/utils/colour';

import * as styles from './styles.module.scss';

interface ProfileCharacterProps {
    onLoad: () => void;
    classname?: string;
    imagePath?: string;
    rivUrl?: string;
    hexColour?: string;
}

interface ProfileCharacterRiveProps extends ProfileCharacterProps {
    riveUrl: string;
}

const ProfileCharacterRive = ({ onLoad, riveUrl }: ProfileCharacterRiveProps) => {
    const { RiveComponent } = useRive({
        src: riveUrl,
        autoplay: true,
        layout: new Layout({
            // fit: Fit.Fill,
            alignment: Alignment.BottomCenter,
        }),
        onLoad: () => {
            if (onLoad) {
                onLoad();
            }
        },
        stateMachines: 'State Machine 1',
    });

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <RiveComponent key={riveUrl}/>
        </div>
    );
};

const ProfileCharacter = ({ classname, onLoad, imagePath, rivUrl, hexColour }: ProfileCharacterProps) => {
    // todo handle loading when changing?
    const theme = useTheme();
    const fallbackImage = theme.customJSON.fallbackCharacterImageUrl;
    if (!rivUrl && !fallbackImage) {
        setTimeout(() => {
            onLoad();
        }, 100);
    }
    return (
        <div className={classNames(styles.ProfileCharacter, classname)} style={getColourFilterStyle('#FB1B08', hexColour)}>
            {rivUrl ? (
                <ProfileCharacterRive onLoad={onLoad} riveUrl={rivUrl} />
            ) : (
                <BackgroundImageWithLoading
                    imageUrl={imagePath ? pathToURL(imagePath) : fallbackImage}
                    onLoad={onLoad}
                />
            )}
        </div>
    );
};

export default ProfileCharacter;

