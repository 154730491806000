import { ThemeStylesProvider } from '../theme';
import { Provider } from '@/state/store';
import { AudioProvider } from '@/theme/audio';
import { GlobalPusherSubscribeProvider } from '@/arise/global/pusher';
import AnalyticsProvider from '@/arise/global/analytics';
import Preloader from '@/components/Preloader';
import PostPurchaseScreen from '@/components/ShopDrawer/PostPurchaseScreen';

import './globals.scss';

export default function RootLayout({ children }: { children: React.ReactNode }) {
    return (
        <Provider>
            <AnalyticsProvider />
            <GlobalPusherSubscribeProvider />
            <AudioProvider />
            <ThemeStylesProvider />
            <Preloader>{children}</Preloader>
            <PostPurchaseScreen />
        </Provider>
    );
}
