import {
    GetAreasResponse,
    GetLocationsBaseResponse,
    GetLocationsResponse,
    GetMediaResponse,
    GetUniverseResponse,
    LoginRequest,
    LoginResponse,
    RegisterRequest,
    RegisterResponse,
    CreateProfileRequest,
    CreateProfileResponse,
    EditProfileRequest,
    EditProfileResponse,
    GetProfilesResponse,
    EditProfileTitleRequest,
    EditProfileTitleResponse,
    TokenExchangeRequest,
    TokenExchangeResponse,
    CommandResponse,
    CommandRequest,
    SetFactionRequest,
    SetFactionResponse,
} from '@/arise/api/types';
import { InventoryItem } from '@/models/Inventory';
import { User } from '@/models/auth';
import { Reaction, ReactionUpdatePayload } from '@/models/feed';
import {
    AvatarFrame,
    Background,
    BanReason,
    Character,
    Faction,
    FactionSettings,
    ProfileAssetType,
    Title,
    UserOwnedAssets,
} from '@/models/profile';
import { ArisewareScore, SupplyRunLeaderboardData, SupplyRunUserStats } from '@/models/misc';
import { AssetPack, CheckoutResponse, ExternalShopItem, SessionStatusResponse, ShopItemTypes } from '@/models/shop';
import { SceneSelectorSettings } from '@/models/misc';
import { pathToURL } from '@/utils/urls';
import { processLocationData } from '@/utils/location';
import { processProfileData } from '@/utils/profile';
import { manageTokenExpiry } from '@/utils/auth';
import { FEED_MESSAGES_TO_LOAD } from '@/globals';

const API_HOST = process.env.PUBLIC_ARISEENGINE_HOST || 'http://localhost:3000';
const AUTHTOKEN_KEY = 'ARISEENGINE_AUTHTOKEN';

let authToken = '';
let universeID = window.localStorage.getItem('ARISE_UNIVERSEID') || process.env.PUBLIC_INSTANCE_ID;

manageTokenExpiry();

if (typeof window !== 'undefined') {
    authToken = window.localStorage.getItem(AUTHTOKEN_KEY) || '';
}

export function setAuthToken(token: string) {
    authToken = token;
    window.localStorage.setItem(AUTHTOKEN_KEY, token);
    manageTokenExpiry();
}

export function getAuthToken() {
    return authToken;
}

export function setUniverseID(id: string) {
    universeID = id;
}

export async function register(username: string, email: string, password: string) {
    const body: RegisterRequest = {
        email: email,
        username: username,
        password: password,
    };

    return await call<RegisterResponse>('/register', 'POST', body);
}

export async function createProfile(body: CreateProfileRequest) {
    return await call<CreateProfileResponse>('/profile/new', 'POST', body);
}

export async function editProfile(profileId: string, body: EditProfileRequest) {
    return await call<EditProfileResponse>(`/profile/${profileId}`, 'PUT', body);
}

export async function setUserCurrentAsset(assetType: ProfileAssetType, assetID: string) {
    const body: any = {
        universeID,
        assetID,
        assetType,
    };

    return await call<EditProfileResponse>(`/userCurrentAssets`, 'POST', body);
}

export async function unsetUserCurrentAsset(assetType: ProfileAssetType) {
    const body: any = {
        universeID,
        assetType,
    };

    return await call<EditProfileResponse>(`/userCurrentAssets`, 'DELETE', body);
}

export async function addProfileTitle(id: string) {
    const body: EditProfileTitleRequest = {
        id,
    };
    return await call<EditProfileTitleResponse>('/profileTitle/add', 'POST', body);
}

export async function setProfileTitle(id: string) {
    const body: EditProfileTitleRequest = {
        id,
    };
    return await call<EditProfileTitleResponse>('/profileTitle/set', 'PUT', body);
}

export async function setProfileFaction(factionID: string) {
    const body: SetFactionRequest = {
        factionID,
    };
    return await call<SetFactionResponse>('/userFaction', 'PUT', body);
}

export async function login(email: string, password: string) {
    const body: LoginRequest = {
        email: email,
        password: password,
    };

    const res = await call<LoginResponse>('/login', 'POST', body);
    setAuthToken(res.authToken);
}

export async function loginAsGuest() {
    const res = await call<LoginResponse>('/login/guest', 'POST');
    setAuthToken(res.authToken);
}

export async function me(): Promise<User> {
    try {
        const res = await call<User>('/me', 'GET');

        return res;
    } catch (error) {
        throw error;
    }
}

async function call<T>(path: string, method: string, body?: any, apiHostOverride?: string) {
    const apiHost = apiHostOverride ?? API_HOST;
    let response = await fetch(`${apiHost}${path}`, {
        method: method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': authToken,
            'X-Universe-ID': universeID,
        },
    });

    if (!response.ok) throw new Error(response.statusText);

    return (await response.json()) as T;
}

export async function getAreas(limit: number = 100) {
    return await call<GetAreasResponse>(`/area/list?universeID=${universeID}&limit=${limit}`, 'GET');
}

export async function getLocations(limit: number = 100) {
    const locations = await call<GetLocationsResponse>(
        `/environment/list?universeID=${universeID}&limit=${limit}`,
        'GET',
    );

    return locations.map((location) => processLocationData(location));
}

export async function getRegions(limit: number = 100) {
    return await call<GetLocationsBaseResponse>(`/region/list?universeID=${universeID}&limit=${limit}`, 'GET');
}

export async function getUserOwnedAssets() {
    return await call<UserOwnedAssets>(`/userOwnedAssets`, 'GET');
}

export async function getBackgrounds() {
    return await call<Background[]>(`/background/list`, 'GET');
}

export async function getCharacters() {
    return await call<Character[]>(`/character/list`, 'GET');
}

export async function getAvatarFrames() {
    return await call<AvatarFrame[]>(`/avatarFrame/list`, 'GET');
}

export async function getFactions() {
    return await call<Faction[]>(`/faction/list`, 'GET');
}

export async function getFactionSettings() {
    return await call<FactionSettings>(`/factionSettings/list`, 'GET');
}

export async function getSceneSelectorSettings() {
    return await call<SceneSelectorSettings>(`/sceneSelectorSettings/list`, 'GET');
}

export async function getProfiles(userIds: string[]) {
    const params = userIds.map((userId) => `id=${userId}`).join('&');
    const profiles = await call<GetProfilesResponse>(`/profile/list?${params}`, 'GET');

    return profiles.map((profile) => processProfileData(profile));
}

export async function getTitles(userId: string) {
    return await call<Title[]>(`/profile/title/history/${userId}`, 'GET');
}

export async function logout() {
    // TODO: call logout endpoint
    setAuthToken('');
}

export async function lastChats(locationId: string, from?: number, num?: number, isGlobalChat?: boolean) {
    // time is a unix timestamp in seconds
    const time = from || Math.ceil(Date.now() / 1000);

    num = num || FEED_MESSAGES_TO_LOAD;

    const messages = await call<any[]>(
        `/chat/lastChats?locationID=${locationId}&time=${time}&num=${num}${isGlobalChat ? '&globalchat=true' : ''}`,
        'GET',
    );

    return messages.map((message) => {
        if (message.data.profile) {
            message.data.profile = processProfileData(message.data.profile);
        }
        return message;
    });
}

// TODO: chat message types
export async function sendMessage(locationId: string, message: string) {
    const body = {
        locationID: locationId,
        chatText: message,
    };

    return await call<any>(`/chat`, 'POST', body);
}

export async function sendCommand(locationId: string, command: string) {
    const body: CommandRequest = {
        environmentID: locationId,
        command,
    };

    return await call<CommandResponse>(`/command`, 'POST', body);
}

export async function getMedia(universeId: string) {
    return await call<GetMediaResponse>(`/universeMedia/` + universeId, 'GET');
}

export async function getUniverseMetadata(universeId: string) {
    return await call<GetUniverseResponse>(`/universe/` + universeId, 'GET');
}

export async function getInventory() {
    const response = await call<any[]>(`/inventory`, 'GET');
    const inventory: InventoryItem[] = response.map((d) => {
        return {
            id: d.id,
            itemId: d.item.id,
            name: d.item.name,
            description: d.item.description,
            inventoryThumbnailUrl: d.item.imagePath ? pathToURL(d.item.imagePath) : d.item.imageURL,
            customJson: d.item.customJson,
        };
    });
    return inventory;
}

export async function takeItem(itemId: string) {
    return call<any>(`/inventory`, 'POST', {
        universeID,
        itemID: itemId,
    });
}

export async function dropItem(inventoryItemId: string) {
    return call<any>(`/inventory/${inventoryItemId}`, 'DELETE');
}

export async function toggleMessageReaction(
    messageId: string,
    reaction: string,
    locationId: string,
): Promise<ReactionUpdatePayload> {
    return await call<ReactionUpdatePayload>(`/reaction/toggle`, 'POST', {
        chatID: messageId,
        reaction,
        environmentID: locationId,
    });
}

export async function listReactionTypes(): Promise<Reaction[]> {
    return await call<Reaction[]>(`/reactions/list`, 'GET');
}

export async function deleteMessage(messageId: string) {
    return await call<any>(`/moderationActions/removeChat`, 'PUT', {
        ChatID: messageId,
    });
}

export async function banUser(userID: string, seconds: number, reason: BanReason) {
    const bannedUntil = new Date(Date.now() + seconds * 1000).toISOString();
    return await call<any>(`/moderationActions/ban`, 'POST', {
        userID,
        bannedUntil,
        reason,
    });
}

export async function getArisewareState(projectId: string) {
    return await call<any>(`/ariseware/state/${projectId}`, 'GET');
}

export async function updateArisewareState(projectId: string, data: any) {
    return await call<any>(`/ariseware/state/${projectId}`, 'PUT', data);
}

// TODO: this endpoint does not exist at the moment, just putting this here for when we have it ready
export async function failedAriseware(projectId: string) {
    // TODO: remove
    return new Promise((resolve) => setTimeout(resolve, 1000));
    // return await call<any>(`/ariseware/failed/${projectId}`, 'POST');
}

export async function getArisewareScoresForUser(userId: string) {
    return await call<ArisewareScore[]>(`/arisewareScore/${userId}`, 'GET');
}

export async function submitArisewareScore(data: any) {
    return await call<any[]>(`/arisewareScore`, 'PUT', data);
}

export async function submitFeedUpdate(locationId: string, id: string) {
    return await call<any>(`/feedUpdate`, 'POST', {
        environmentID: locationId,
        id,
    });
}

export async function searchByDisplayName(query: string) {
    return await call<any>(`/profile/searchByDisplayName?query=${encodeURIComponent(query)}`, 'GET');
}

export async function getEventById(eventID: string) {
    return await call<any>(`/chatEvent?eventID=${eventID}`, 'GET');
}

export type TokenExchangeRequest = {
    source: 'insiders';
    token: string;
};

export type TokenExchangeResponse = {
    ariseToken: string;
    me: User;
};

export async function tokenExchange(token: string, source: string) {
    const res = await call<TokenExchangeResponse>(`/auth/tokenExchange`, 'POST', {
        token,
        source,
    });

    setAuthToken(res.ariseToken);
    return res;
}

export async function heartbeat(locationId: string, justJoinedLocation: boolean = false) {
    try {
        await call<any>('/presence/heartbeat', 'POST', {
            environmentID: locationId,
            ...(justJoinedLocation && { joinedLocation: true }),
        });
    } catch (error) {
        console.error('Failed to send heartbeat', error);
    }
}

export async function tunaLab(locationId: string, data: any) {
    return await call<any>(`/tunaLab`, 'POST', {
        environmentID: locationId,
        data,
    });
}

// Amplitude funnel
export async function scroll(data: any) {
    return await call<any>(`/scroll`, 'POST', data);
}

export function getInstanceData() {
    return {
        universeID,
        userAuthToken: authToken,
        apiHost: API_HOST,
    };
}

// Shop APIs
export async function getAssetPacks() {
    return await call<AssetPack[]>(`/assetpack/list`, 'GET');
}

export async function getShopExternalItems() {
    return await call<ExternalShopItem[]>(`/shop/external`, 'GET');
}

export async function checkoutShopItem(itemID: string, type: ShopItemTypes) {
    let body = {
        items: [
            {
                type,
                id: itemID,
            },
        ],
        redirectURL: window.location.href + '?checkout=true',
    };
    return await call<CheckoutResponse>('/shop/checkout', 'POST', body);
}

export async function getSessionStatus(id: string) {
    return await call<SessionStatusResponse>(`/shop/checkout/${id}`, 'GET');
}

// Supply run APIs
const SUPPLY_RUN_API_HOST = process.env.PUBLIC_SUPPLY_RUN_HOST;

export async function getSupplyRunLeaderboardData() {
    return await call<SupplyRunLeaderboardData>(`/supply-run/stats`, 'GET', null, SUPPLY_RUN_API_HOST);
}

export async function getSupplyRunUserProfileStats(userID: string) {
    return await call<SupplyRunUserStats>(
        `/supply-run/user-profile-stats?userId=${userID}`,
        'GET',
        null,
        SUPPLY_RUN_API_HOST,
    );
}
