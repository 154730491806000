/**
 * Returns number converted to an oridinal string, or fallback if not a number
 * @param number example: 2
 * @param fallback example: 'N/A'
 * @returns {string} example '2nd'
 */
export function convertNumberToOrdinal(number: number, fallback: string | number = '-'): string {
    if (typeof number !== 'number' || isNaN(number)) {
        return `${fallback}`;
    }
    let ordinalSuffix: string;
    if (number % 10 === 1 && number % 100 !== 11) {
        ordinalSuffix = 'st';
    } else if (number % 10 === 2 && number % 100 !== 12) {
        ordinalSuffix = 'nd';
    } else if (number % 10 === 3 && number % 100 !== 13) {
        ordinalSuffix = 'rd';
    } else {
        ordinalSuffix = 'th';
    }
    return `${number}${ordinalSuffix}`;
}

/**
 * Returns number converted to localised string, or fallback if not a number
 * @param number example: 3000000
 * @param fallback example: 'N/A'
 * @returns {string} example '3,000,000'
 */
export function localiseNumber(number: number, fallback: string | number = 0): string {
    if (typeof number !== 'number' || isNaN(number)) {
        return `${fallback}`;
    }
    return number.toLocaleString('en', {
        useGrouping: true,
    });
}
