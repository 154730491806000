import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';

import { useTheme } from '@/theme';
import { FeedItem } from '@/models/feed';
import { useAppSelector } from '@/state/store';
import ChatWidget from '@/components/ChatWidget';
import QuickReact from '@/components/QuickReact';

import * as styles from './styles.module.scss';

type Status = 'chatWidgetState' | 'chatWidgetAndQuickReactState' | 'hiddenState';
const convertToNumber = (text: string) => (text ? parseInt(text) : null);

export default function QuickChatDrawer() {
    const { messages, isLoadingPastMessages } = useAppSelector((state) => state.feed);
    const { isSidebarOpen, arisewareIsSnackbarVisible } = useAppSelector((state) => state.app);
    const theme = useTheme();

    // Settings can be overridden using customJSON if needed
    const settings = {
        highlightedMessageVisibleDurationMs:
            convertToNumber(theme.customJSON['highlightedMessageSettings']?.['visibleDurationMs']) ?? 4000,
        highlightedMessageGapDurationMs:
            convertToNumber(theme.customJSON['highlightedMessageSettings']?.['gapDurationMs']) ?? 10000,
        highlightedMessageMaxMessageLength:
            convertToNumber(theme.customJSON['highlightedMessageSettings']?.['maxMessageLength']) ?? 35,
        highlightedMessageMinReactions:
            convertToNumber(theme.customJSON['highlightedMessageSettings']?.['minReactions']) ?? 3,
    };

    const [highlightedMessage, setHighlightedMessage] = useState(null);
    const [visualStatus, setVisualStatus] = useState<Status>('chatWidgetState');
    const seenMessageIds = useRef<string[]>([]);

    function getMessageToHighlight(items: FeedItem[]) {
        // Check every message from newest to oldest
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const shouldBeHighlighted =
                item.type === 'chat-message' &&
                !seenMessageIds.current.includes(item.id) &&
                item.data?.message?.length <= settings.highlightedMessageMaxMessageLength &&
                item.data?.reactions?.length >= settings.highlightedMessageMinReactions;

            if (shouldBeHighlighted) {
                // Found a message to highlight!
                seenMessageIds.current = [];
                for (let j = i; j < items.length; j++) {
                    // This message and messages older than it should never be highlighted again (to stop messages showing out of order)
                    // So add them to seenMessageIds
                    seenMessageIds.current.push(items[j].id);
                }
                return item;
            }
        }
        return null;
    }

    function setAllMessagesAsSeen() {
        // We only check the 10 most recent messages, so we only need to set last 10 as seen
        const last10Items = messages.slice(-10);
        seenMessageIds.current = last10Items.map((item) => item.id);
    }

    useEffect(() => {
        if (!isSidebarOpen) {
            // When user closes the chat, set all previous messages as seen
            setAllMessagesAsSeen();
        }
    }, [isSidebarOpen]);

    useEffect(() => {
        // When historical messages first load in, set them all as seen
        if (!isLoadingPastMessages && messages.length) {
            setAllMessagesAsSeen();
        }
    }, [isLoadingPastMessages]);

    useEffect(() => {
        if (!isSidebarOpen && !highlightedMessage && !isLoadingPastMessages) {
            // If chat closed, and if no current highlighted message
            const last10Items = messages.slice(-10).reverse();
            const result = getMessageToHighlight(last10Items);
            if (result) {
                // Highlight a message
                setHighlightedMessage(result);
            }
        }
    }, [messages]);

    useEffect(() => {
        // When highlighted message changes
        if (!highlightedMessage) return;
        // Show it
        setVisualStatus('chatWidgetAndQuickReactState');
        // Hide it after n MS
        const visibleTimeout = setTimeout(() => {
            setVisualStatus('chatWidgetState');
        }, settings.highlightedMessageVisibleDurationMs);
        // Allow new one to appear after n MS
        const clearMessageTimeout = setTimeout(() => {
            setHighlightedMessage(null);
        }, settings.highlightedMessageVisibleDurationMs + settings.highlightedMessageGapDurationMs);
        return () => {
            clearTimeout(visibleTimeout);
            clearTimeout(clearMessageTimeout);
            setVisualStatus('chatWidgetState');
            setHighlightedMessage(null);
        };
    }, [highlightedMessage]);

    const visualStyle = arisewareIsSnackbarVisible === false ? 'hiddenState' : visualStatus;
    const chatWidgetIsHidden = arisewareIsSnackbarVisible === false || isSidebarOpen;
    const quickReactIsHidden = visualStatus !== 'chatWidgetAndQuickReactState';

    return (
        <div className={classNames(styles.QuickChatDrawer, styles[visualStyle])}>
            <div className={styles.chatWidget} inert={chatWidgetIsHidden ? '' : undefined}>
                <ChatWidget />
            </div>
            <div className={styles.quickReact} inert={quickReactIsHidden ? '' : undefined}>
                <QuickReact message={highlightedMessage} />
            </div>
        </div>
    );
}
