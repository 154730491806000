.AvatarLayered {
    position: relative;
    display: block;
    flex: none;

    img,
    .masked {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0; // Fixes safari bug where overflow is not hidden
        pointer-events: none;
    }

    .masked {
        border-radius: 50%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);
    }

    .frame {
        top: -35%;
        left: -35%;
        width: 170%;
        max-width: none;
    }

    .background {
        width: auto;
        height: 200%;
    }

    .character {
        top: 5px;
        transition: transform 0.5s ease-out;
    }

    &.hasHover {
        cursor: pointer;

        &:hover,
        &:focus {
            .character {
                transform: scale(1.2) translateY(10%);
            }
        }
    }
}
