@import '../../style/globals.scss';
$top-height: 80px;
$character-transition-duration: 1.5s;

.ProfileDrawer {
    position: absolute;
    z-index: 120;
    top: 0;
    right: -$sidebar-width;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--token-accent-theme-1-text-text-default);
    backdrop-filter: blur(30px);
    transition: $sidebar-transition, opacity 0s linear 0.5s;
    opacity: 0;

    @media (max-width: $bp-mobile-chat) {
        right: -100%;
        width: 100%;
    }

    &.isOpen {
        right: 0;
        transition: $sidebar-transition, opacity 0s linear 0s;
        opacity: 1;

        &.isLoaded {
            .factionImage {
                transform: scale(1);
            }

            .profileCharacter {
                transform: translateX(0%);
            }
        }
    }

    .profileCharacterContainer {
        position: relative;
        overflow: hidden;
        height: 500px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 80px;
            background: linear-gradient(180deg, black 0%, transparent 100%);
            pointer-events: none;
            opacity: 0.9;
        }

        .profileBackground {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .profileCharacter {
            position: absolute;
            bottom: 0;
            left: 90px;
            width: 100%;
            height: calc(100% - 70px);
            pointer-events: none;
            transition: transform $character-transition-duration ease-out, left 500ms ease-in-out;
            transform: translateX(50%);

            @media (max-width: $bp-s) {
                left: 30px;
            }
        }

        .factionImage {
            position: absolute;
            bottom: 200px;
            left: 10px;
            width: 140px;
            animation: none;
            pointer-events: none;
            transition: transform 500ms ease-out, opacity 200ms ease-in-out;
            transform: scale(0.8);
        }
    }

    .editingAvatar {
        top: 500px - 70px;
        left: 50%;
        width: 40px;
        height: 40px;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 200ms ease-in-out;
    }

    .isEditing {
        .profileCharacter {
            left: 0px;
        }
        .factionImage {
            opacity: 0;
        }
        .editingAvatar {
            opacity: 1;
        }
    }

    .topBar {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: $top-height;
        padding-top: 30px;
        padding-right: 20px;

        .closeButton {
            position: absolute;
            top: 10px;
            right: 20px;
            svg {
                vertical-align: middle;
            }
        }

        .shopButton {
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

    .editButton {
        position: absolute;
        right: 40px;
        bottom: 30px;
        width: 40px;
        height: 40px;
    }

    .content {
        @include scrollbar-styles;
        position: relative;
        z-index: 1;
        overflow-y: scroll;
        height: 100%;
    }

    .bottomContent {
        position: relative;
        z-index: 1;
        padding-right: 22px;
        padding-left: 22px;
        margin-top: -2px;
        transition: opacity 0.1s linear;

        &.isEditing {
            opacity: 0;
            pointer-events: none;
        }
    }

    .themedBar {
        width: 100%;
        margin-bottom: 20px;
        font-size: $font-size-p;
        font-weight: 500;

        .avatar {
            width: 40px;
            height: 40px;
            margin-right: 17px;
        }

        .themedBarContent {
            position: absolute;
            left: 28px;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    .titlesUnlocked {
        padding: 15px;

        .titleStat {
            margin-bottom: 20px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            height: 100%;
            gap: 7px;
        }
    }

    .loadingScreen {
        z-index: 10;
        background-color: black;
        transition: none;
    }
}
