@import '../../../style/globals.scss';

.ProfileBackground {
    width: 100%;
    height: 100%;

    div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top center;
    }
}
