@import '../../../style/globals.scss';

.EventSummaryMessage {
    position: relative;
    margin: 0 0 5px;
    color: var(--token-accent-theme-1-text-text-default);
    font-size: $font-size-p;
    border-radius: 10px;

    .container {
        width: 100%;
        height: 100%;
        padding: 20px;
        mask-image: url('/assets/themes/titan/images/events/event-summary-rect-mask.webp');
        mask-size: 100% 100%;
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-foreground);

        &.failed {
            background-color: var(--token-arise-theme-1-state-state-error-dark);

            .heading {
                color: var(--token-arise-theme-1-state-state-error-light);
            }
        }

        &.success {
            background-color: var(--token-arise-theme-1-state-state-success-dark);

            .heading {
                color: var(--token-arise-theme-1-state-state-success-light);
            }
        }
    }

    .heading {
        display: inline-block;
        max-width: calc(100% - 160px);
        min-width: 80%;
        font-family: var(--font-primary);
        font-size: $font-size-p;
    }

    .text {
        color: var(--token-accent-theme-1-text-text-default);
        max-width: calc(100% - 180px);
        min-width: 80%;

        p {
            display: block;
        }
    }

    .bold {
        font-weight: bold;
    }

    .successHighlight {
        color: var(--token-arise-theme-1-state-state-success-light);
    }

    .failedHighlight {
        color: var(--token-arise-theme-1-state-state-error-light);
    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
        max-width: 30%;
        height: 100%;
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-background);
        mask-image: url('/assets/themes/titan/images/events/event-summary-slanted-mask.webp');
        mask-size: 100% 100%;
        background-size: cover;
        background-position: right;
    }

    .existingReactions {
        margin-top: 12px;
    }

    .reactionsMenu {
        opacity: 0;
    }

    &:hover,
    &:focus,
    &:focus-within {
        .reactionsMenu {
            opacity: 1;
        }
    }

    .content {
        height: 80px;
        display: flex;
        align-items: center;
        width: 90%;

        .contentCenter {
            width: 100%;
        }
    }
}
