@import '../../style/globals.scss';

.Tooltip {
    padding: 5px 10px;
    background-color: var(--token-accent-theme-1-interface-mono-interface-white);
    color: var(--token-accent-theme-1-text-text-contrast-default);
    font-size: $font-size-p;
    white-space: nowrap;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    &.autoPosition {
        position: absolute;
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
    }

    &.isVisible {
        opacity: 1;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -16px;
        border-width: 8px 10px;
        transform: translateY(-50%);
        border-style: solid;
        border-color: transparent var(--token-accent-theme-1-interface-mono-interface-white) transparent transparent;
    }
}
