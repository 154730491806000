import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useAudio } from '@/hooks/audio';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { removeFirstAchievementFromQueue } from '@/state/features/app';

import * as styles from './styles.module.scss';
import Achievement from '../Achievement';

const TRANSITION_IN_DURATION = 300; // Duplicated in scss
const ACHIEVEMENT_STATIC_DURATION = 4000;
const TRANSITION_OUT_DURATION = 120; // Duplicated in scss
const QUEUE_GAP_DURATION = 1000;

export default function AchievementFeed() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();
    const { achievementQueue } = useAppSelector((state) => state.app);
    const isGuest = useCurrentUserIsGuest();
    const [isVisible, setIsVisible] = useState(false); // Controls achievement CSS transition
    const [isPlaying, setIsPlaying] = useState(false); // If achievement animation is currently playing
    const [achievementIsLoaded, setAchievementIsLoaded] = useState(false); // If image inside current achievement is loaded

    useEffect(() => {
        if (achievementQueue[0] && achievementIsLoaded && !isPlaying) {
            // Animate in
            setIsPlaying(true);
            setIsVisible(true);
            audio?.play(getAudioNameByAchievementType(achievementQueue[0].type));

            setTimeout(() => {
                // Animate out
                setIsVisible(false);
            }, TRANSITION_IN_DURATION + ACHIEVEMENT_STATIC_DURATION);

            setTimeout(() => {
                // Delete achievement from queue
                dispatch(removeFirstAchievementFromQueue());
                setIsPlaying(false);
                setAchievementIsLoaded(false);
            }, TRANSITION_IN_DURATION + ACHIEVEMENT_STATIC_DURATION + TRANSITION_OUT_DURATION + QUEUE_GAP_DURATION);
        }
    }, [achievementQueue, achievementIsLoaded]);

    const currentAchievement = achievementQueue[0];
    if (!currentAchievement || isGuest) return <></>;
    return (
        <div className={styles.AchievementFeed}>
            <div
                role="alert"
                aria-live="assertive"
                className={classNames(styles.achievementContainer, { [styles.visible]: isVisible })}
            >
                <Achievement
                    type={currentAchievement.type}
                    icon={currentAchievement.icon}
                    actionPrefix={currentAchievement.actionPrefix}
                    action={currentAchievement.action}
                    onLoaded={() => setAchievementIsLoaded(true)}
                    isVisible={isVisible}
                    hasLineBreak={currentAchievement.hasLineBreak}
                />
            </div>
        </div>
    );
}

function getAudioNameByAchievementType(type: string) {
    switch (type) {
        case 'success':
            return 'positive-achievement';
        case 'danger':
            return 'negative-achievement';
        case 'default':
            return 'positive-achievement';
        default:
            return 'positive-achievement';
    }
}
