@import '../../../style/globals.scss';

.imagePlaceholder {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}
.namePlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.messagePlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.reactionsPlaceholder {
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: rgba($color: #000000, $alpha: 0);
}

.ModerationActions {
    position: absolute;
    top: 0;
    right: 5px;
    text-align: center;
    opacity: 0;
}

.modActionsOptions {
    position: absolute;
    z-index: 30;
    width: 200px;
    right: 0px;
    border: 1px solid white;
    background-color: rgb(15, 15, 15);

    &.isOffBottom {
        bottom: 10px;
    }

    button {
        width: 100%;
        height: 100%;
        border: 1px solid white;
        padding: 5px;
        margin-top: 5px;
        &:hover {
            background-color: #330101;
            text-decoration: underline;
        }
    }
}

.ChatMessage {
    padding: 10px 10px 10px 66px;
    border-radius: 10px;
    word-break: break-word;

    .reactionsMenu {
        opacity: 0;
    }

    &.highlighted {
        background-color: var(--token-arise-theme-1-state-state-success-dark);

        &:hover,
        &:focus,
        &:focus-within {
            background-color: var(--token-arise-theme-1-state-state-success-dark);
        }
    }

    &:hover,
    &:focus,
    &:focus-within {
        background-color: var(--token-LEGACY-accent-theme-1-interface-interface-midground);

        .reactionsMenu {
            opacity: 1;
        }

        .ModerationActions {
            opacity: 1;
        }
    }

    &.isGlobalChatMessage {
        border: 1px solid var(--token-accent-theme-1-interface-interface-midground);
    }

    .avatar {
        position: absolute;
        top: 10px;
        left: 13px;
        width: 40px;
        height: 40px;
    }

    .mention {
        font-weight: bold;
        color: var(--token-arise-theme-1-state-state-success-light);
    }

    .messageText {
        margin-top: 6px;
    }

    opacity: 0.5;
    &.visible {
        opacity: 1;
    }
}

.LocationLink {
    color: var(--token-accent-theme-1-interface-interface-black);
    background-color: var(--token-accent-theme-1-interface-interface-white);
    padding: 3px 10px;
    border-radius: 18px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: inline-block;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
}
