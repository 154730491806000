import { Currency } from '@/models/shop';

/**
 * Converts number into price string
 * @param amount in pennies/cents, example: 120
 * @param currency example: USD
 * @returns {string} example $1.20
 */
export function getPriceString(currency: Currency, amount: number): string | null {
    try {
        if (isNaN(amount)) {
            console.error(`Could not format price for item, price is not a number: ${amount}`);
            return null;
        }
        return (amount / 100).toLocaleString('en-US', { style: 'currency', currency: currency });
    } catch (error) {
        console.error(
            `Could not format price for item, values are currency: ${currency}, amount: ${amount}. Error: ${error.message}`,
        );
        return null;
    }
}
