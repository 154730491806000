import { useRef } from 'react';

import Settings from '@/components/Settings';
import { useAppSelector } from '@/state/store';

interface FullpageWrapperProps {
    children: React.ReactNode;
    pageHasProfileDrawer?: boolean;
}

export default function FullpageWrapper({ children, pageHasProfileDrawer }: FullpageWrapperProps) {
    const isSettingsMenuShowing = useAppSelector((state) => state.app.isSettingsMenuShowing);
    const isPurchaseInProgress = useAppSelector((state) => state.app.isPurchaseInProgress);
    const container = useRef<HTMLDivElement>(null);
    const shouldBeInert = isSettingsMenuShowing || isPurchaseInProgress;

    return (
        <div
            className="absolute top-0 left-0 w-full h-full overflow-hidden"
            style={{
                touchAction: 'none',
            }}
            ref={container}
            onScroll={() => {
                // Stop scroll jumping to items which become focussed off screen
                container.current.scrollTo(0, 0);
            }}
        >
            <Settings visible={isSettingsMenuShowing} displayNameShouldLinkToProfile={pageHasProfileDrawer} />

            <div className="absolute top-0 left-0 w-full h-full" inert={shouldBeInert ? '' : undefined}>
                {children}
            </div>
        </div>
    );
}
