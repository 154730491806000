import classNames from 'classnames';
import { useTheme } from '@/theme';
import * as styles from './styles.module.scss';

interface ThemedButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

export default function ThemedButton({ text, onClick, disabled, className }: ThemedButtonProps) {
    const theme = useTheme();
    const maskImage = theme.customJSON.themedButtonBackgroundMaskImage;
    return (
        <button
            className={classNames(styles.ThemedButton, className)}
            onClick={onClick}
            style={maskImage ? { maskImage: `url(${maskImage})` } : {}}
            disabled={disabled}
        >
            <span>{text}</span>
        </button>
    );
}

interface ThemedLinkButtonProps {
    text: string;
    href: string;
    className?: string;
    onClick?: () => void;
}

export function ThemedLinkButton({ text, href, className, onClick }: ThemedLinkButtonProps) {
    const theme = useTheme();
    const maskImage = theme.customJSON.themedButtonBackgroundMaskImage;
    return (
        <a
            className={classNames(styles.ThemedButton, className)}
            href={href}
            style={maskImage ? { maskImage: `url(${maskImage})` } : {}}
            onClick={onClick ? onClick : null}
        >
            <span>{text}</span>
        </a>
    );
}
