$transitionTime: 1000ms;
.FailOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    translate: -100% 0;
    transition: translate $transitionTime ease-in-out;

    img {
        transition: height 0ms;
        transition-delay: $transitionTime;
        height: 0;
        max-width: initial;
    }
}

.FailOverlayBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background-color: var(--token-LEGACY-accent-theme-1-interface-interface-black);
    transition: opacity $transitionTime ease-in-out, width 0ms ease-in-out;
    transition-delay: 0ms, $transitionTime;

    opacity: 0;
}

.failed {
    &.FailOverlay {
        translate: 0;
        img {
            transition-delay: 0ms;
            height: 100%;
        }
    }
    &.FailOverlayBg {
        opacity: 1;
        width: 100%;
        transition-delay: 0ms;
    }
}
