import { useEffect } from 'react';
import classNames from 'classnames';

interface BackgroundImageWithLoadingProps {
    imageUrl: string;
    onLoad?: () => void;
    classname?: string;
}

const BackgroundImageWithLoading = ({ onLoad, classname, imageUrl }: BackgroundImageWithLoadingProps) => {
    useEffect(() => {
        if (!onLoad) return;
        const img = new Image();
        img.src = imageUrl;
        if (img.complete) {
            onLoad();
        } else {
            img.onload = onLoad;
        }
    }, [imageUrl, onLoad]);

    return (
        <div
            className={classNames(classname)}
            style={{
                backgroundImage: `url("${imageUrl}")`,
            }}
        ></div>
    );
};

export default BackgroundImageWithLoading;
