@import '../../../style/globals.scss';

$height: 40px;

.Update {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
    padding: 10px 10px 10px 66px;
    background-color: var(--token-arise-theme-1-state-state-default-dark);
    font-size: $font-size-p;
    font-weight: 500;
    border-radius: 10px;

    .displayName {
        color: var(--token-accent-theme-1-text-text-default);
    }

    .action {
        color: var(--token-arise-theme-1-state-state-default-light);
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: $height;
        height: $height;
        border-radius: $height;
        transform: translateY(-50%);
        background-color: var(--token-arise-theme-1-state-state-default-mid);

        svg {
            position: absolute;
            $space: 7px;
            top: $space;
            left: $space;
            width: $height - $space * 2;
            height: $height - $space * 2;
            object-fit: contain;
        }
    }

    &.danger {
        background-color: var(--token-arise-theme-1-state-state-error-dark);

        .displayName {
            color: var(--token-accent-theme-1-text-text-default);
        }
        .action {
            color: var(--token-arise-theme-1-state-state-error-light);
        }
        .icon {
            background-color: var(--token-arise-theme-1-state-state-error-mid);
        }
    }

    &.success {
        background-color: var(--token-arise-theme-1-state-state-success-dark);

        .displayName {
            color: var(--token-accent-theme-1-text-text-default);
        }
        .action {
            color: var(--token-arise-theme-1-state-state-success-light);
        }
        .icon {
            background-color: var(--token-arise-theme-1-state-state-success-mid);
        }
    }

    .reactionsMenu {
        opacity: 0;
    }

    &:hover,
    &:focus,
    &:focus-within {
        .reactionsMenu {
            opacity: 1;
        }
    }
}
