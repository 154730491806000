@import '../../style/globals.scss';

.FloatingHeaderBar {
    $margin: 15px;
    position: absolute;
    top: $margin;
    left: $margin;

    &.isExpanded {
        $top-padding: 70px;
        $button-size: 50px;
        $button-margin: 10px;

        .button {
            transition: top 400ms $animation-overshoot;
            // Calculates expanded top distance for up to 10 buttons
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    top: $top-padding + ($button-size + $button-margin) * ($i - 1);
                }
            }
        }
    }

    .button {
        position: absolute;
        top: 0px;
        transition: top 200ms $animation-bounce;
    }

    .buttonsContainer {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .expandButton {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: var(--token-accent-theme-1-brand-palette-brand-colour);
        border-radius: 50%;

        &:hover {
            .logoIcon {
                transform: scale(1.1);
            }

            .buttonStatus {
                filter: brightness(1.4);
            }
        }

        .buttonStatus {
            position: absolute;
            bottom: -4px;
            left: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 13px;
            background-color: var(--token-accent-theme-1-interface-brand-interface-midground);
            border-radius: 8px;
            transition: filter 200ms $animation-bounce;
        }

        .logoIcon {
            width: 42px;
            height: 42px;
            transition: transform 200ms $animation-bounce;
        }
    }
}
