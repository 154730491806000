import { useEffect, useRef, TransitionEvent, useState } from 'react';
import classNames from 'classnames';

import { LS_HASSEENSHOP_KEY } from '@/globals';
import { getAssetPacks, getShopExternalItems } from '@/arise/api';
import { AssetPack, ExternalShopItem } from '@/models/shop';
import { getPriceString } from '@/utils/currency';
import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import { useTheme } from '@/theme';
import { setHasSeenShop, setIsShopOpen } from '@/state/features/app';
import IconButton from '@/components/IconButton';
import LoadingScreen from '@/components/LoadingScreen';
import BundleItem from './BundleItem';
import BundleModal from './BundleModal';
import ShopExternalItem from './ShopExternalItem';

import * as styles from './styles.module.scss';

export default function ShopDrawer() {
    const dispatch = useAppDispatch();
    const { audio } = useAudio();
    const theme = useTheme();
    const isShopOpen = useAppSelector((state) => state.app.isShopOpen);
    const [currentlyShowingModalAssetPack, setCurrentlyShowingModalAssetPack] = useState<AssetPack>(null);
    const [currentTab, setCurrentTab] = useState<'profileShop' | 'externalShop'>('profileShop');

    const [packs, setPacks] = useState<AssetPack[]>([]);
    const [packsAreLoading, setPacksAreLoading] = useState<boolean>(true);
    const [externalItems, setExternalItems] = useState<ExternalShopItem[]>([]);
    const [externalItemsAreLoading, setExternalItemsAreLoading] = useState<boolean>(true);

    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const hasExternalShop = theme.customJSON.hasExternalShop;

    const PROFILE_SHOP_TITLE = 'Profile Decoration Bundles';
    const EXTERNAL_SHOP_TITLE = 'Exclusive Merchandise';

    const loadAssetPacks = () => {
        const isPackValid = (pack: AssetPack) => {
            const isValid =
                getPriceString(pack.currency, pack.price) !== null &&
                pack.assets?.avatarFrame?.id &&
                pack.assets?.background?.id &&
                pack.assets?.character?.id;
            if (!isValid) console.error('Not showing invalid pack:', pack);
            return isValid;
        };
        getAssetPacks()
            .then((packs) => {
                const validPacks = packs.filter(isPackValid).sort((a, b) => a.order - b.order);
                setPacks(validPacks);
                setPacksAreLoading(false);
            })
            .catch(() => {
                setPacksAreLoading(false);
                console.error('Error loading asset packs');
            });
    };

    const loadExternalItems = () => {
        const isValidExternalItem = (item: ExternalShopItem) => {
            const isValid = item.title && item.link && item.imagePath;
            if (!isValid) console.error('Not showing invalid external shop item:', item);
            return isValid;
        };
        getShopExternalItems()
            .then((items) => {
                const validItems = items.filter(isValidExternalItem).sort((a, b) => a.order - b.order);
                setExternalItems(validItems);
                setExternalItemsAreLoading(false);
            })
            .catch(() => {
                setExternalItemsAreLoading(false);
                console.error('Error loading external shop items');
            });
    };

    useEffect(() => {
        if (isShopOpen) {
            audio?.play('gust-open');
            // Reload data when opened
            setPacksAreLoading(true);
            loadAssetPacks();
            if (hasExternalShop) {
                setExternalItemsAreLoading(true);
                loadExternalItems();
            }
            sendEvent('view_shop');
            dispatch(setHasSeenShop(true));
            window.localStorage.setItem(LS_HASSEENSHOP_KEY, 'true');
        }
    }, [isShopOpen]);

    // Move focus to drawer when opened
    function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
        if (isShopOpen && e.target === e.currentTarget) {
            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }

    const getProfileShopContent = () => {
        if (packsAreLoading) return null;
        return (
            <>
                <h2 className={styles.title}>{PROFILE_SHOP_TITLE}</h2>
                {packs.length === 0 && <p className={styles.emptyText}>No bundles to show</p>}
                <ul className={classNames(styles.assets)}>
                    {packs.map((pack, index) => {
                        return (
                            <li key={`shop-profile-${index}`}>
                                <BundleItem
                                    onSelected={() => {
                                        setCurrentlyShowingModalAssetPack(pack);
                                        sendEvent('profile_shop_bundle_open', {
                                            id: pack?.id,
                                            name: pack?.name,
                                            price: pack?.price,
                                        });
                                    }}
                                    pack={pack}
                                />
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    const getExternalShopContent = () => {
        if (externalItemsAreLoading) return null;
        return (
            <>
                <h2 className={styles.title}>{EXTERNAL_SHOP_TITLE}</h2>
                {externalItems.length === 0 && <p className={styles.emptyText}>No items to show</p>}
                <ul className={classNames(styles.assets)}>
                    {externalItems.map((externalItem, index) => {
                        return (
                            <li key={`shop-external-${index}`}>
                                <ShopExternalItem item={externalItem} />
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    return (
        <div
            className={classNames(styles.ShopDrawer, {
                [styles.isOpen]: isShopOpen,
            })}
            inert={!isShopOpen ? '' : undefined}
            onTransitionEnd={onTransitionEnd}
            style={{ backdropFilter: `blur(10px)` }}
        >
            <div className={styles.scrollContainer}>
                <div className={styles.topBar}>
                    <h2 className={styles.title}>Shop</h2>
                    <IconButton
                        buttonRef={closeButtonRef}
                        iconType="close"
                        buttonStyle="round"
                        ariaLabel="Close Shop"
                        className={styles.closeButton}
                        onClick={() => {
                            audio?.play('gust-close');
                            dispatch(setIsShopOpen(false));
                        }}
                    />
                </div>
                <div
                    className={classNames(styles.content)}
                    style={{
                        backgroundImage: `url("${theme.customJSON?.profileBackground}")`,
                        borderImageSource: `url("${theme.customJSON?.profileBackground}")`,
                    }}
                >
                    {hasExternalShop && (
                        <ul className={styles.tabButtons}>
                            <li>
                                <IconButton
                                    className={classNames({ [styles.isActive]: currentTab === 'profileShop' })}
                                    iconType="character"
                                    buttonStyle="icon"
                                    ariaLabel={PROFILE_SHOP_TITLE}
                                    title={PROFILE_SHOP_TITLE}
                                    onClick={() => {
                                        sendEvent('shop_external_tab_click');
                                        setCurrentTab('profileShop');
                                    }}
                                />
                            </li>
                            <li>
                                <IconButton
                                    className={classNames({ [styles.isActive]: currentTab === 'externalShop' })}
                                    iconType="box"
                                    buttonStyle="icon"
                                    ariaLabel={EXTERNAL_SHOP_TITLE}
                                    title={EXTERNAL_SHOP_TITLE}
                                    onClick={() => {
                                        sendEvent('shop_profile_tab_click');
                                        setCurrentTab('externalShop');
                                    }}
                                />
                            </li>
                        </ul>
                    )}
                    <div className={styles.items}>
                        {currentTab === 'profileShop' ? getProfileShopContent() : getExternalShopContent()}
                        <LoadingScreen
                            hasBackground={false}
                            show={currentTab === 'profileShop' ? packsAreLoading : externalItemsAreLoading}
                        />
                    </div>
                </div>
            </div>

            {currentlyShowingModalAssetPack && (
                <BundleModal
                    pack={currentlyShowingModalAssetPack}
                    onClose={() => setCurrentlyShowingModalAssetPack(null)}
                />
            )}
        </div>
    );
}
