import { useInitialAuthCheck } from '@/hooks/auth';
import { useAppSelector } from '@/state/store';
import { profileHasOnboarded } from '@/utils/profile';
import WorldIntro from '@/components/WorldIntro';

export default function LocationLayout({ children }: { children: React.ReactNode }) {
    useInitialAuthCheck();
    const loggedIn = useAppSelector((state) => state.auth.loggedIn);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    if (!loggedIn || !profileHasOnboarded(currentUserProfile)) {
        return <></>;
    }
    return <WorldIntro>{children}</WorldIntro>;
}
